import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "../widget/Button";
import constants from "../constants";

const NotFound = () => {
  const navigate = useNavigate();
  const [vibrating, setVibrating] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      setVibrating(true);

      controls.start({
        x: [0, -5, 5, -5, 5, 0],
        transition: { duration: 0.2 },
      });

      setTimeout(() => {
        setVibrating(false);
        controls.start({ x: 0 });
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, [controls]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <motion.img
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, type: "spring", damping: 8, stiffness: 70 }}
        src={constants.images.pageNotFound}
        alt="Dataphion Not Found"
        className="w-1/2"
      />
      <motion.div
        className={`vibrating-button ${vibrating ? "vibrating" : ""}`}
        animate={controls}
      >
        <Button
          variant="contained"
          startIcon={<i className="fa-solid fa-angle-left" />}
          onClick={() => navigate(constants.route.default)}
        >
          Back to Home
        </Button>
      </motion.div>
    </div>
  );
};

export default NotFound;
