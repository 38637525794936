import { motion } from "framer-motion";

const Typography = ({ variant, className, children, ...rest }) => {
  let textClass = "";

  switch (variant) {
    case "h1":
      textClass = "lg:text-5xl xs:text-4xl font-bold";
      break;
    case "h2":
      textClass = "text-4xl font-bold";
      break;
    case "h3":
      textClass = "text-3xl font-bold";
      break;
    case "h4":
      textClass = "text-2xl font-bold";
      break;
    case "h5":
      textClass = "text-xl font-bold";
      break;
    case "h6":
      textClass = "text-lg font-bold";
      break;
    case "subtitle1":
      textClass = "text-lg";
      break;
    case "subtitle2":
      textClass = "text-md";
      break;
    case "body1":
      textClass = "text-sm";
      break;
    case "body2":
      textClass = "text-xs";
      break;
    default:
      textClass = "text-[11px]";
      break;
  }
  return (
    <motion.div {...rest} className={className}>
      <p className={textClass}>{children}</p>
    </motion.div>
  );
};

export default Typography;
