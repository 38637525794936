import React from "react";
import Typography from "./Typography";
import { motion } from "framer-motion";

const Input = ({
  value,
  onChange,
  placeholder,
  label,
  className,
  noOutline,
  name,
  type,
  error,
  ...rest
}) => {
  const topBottomVariant = {
    hidden: {
      y: -4,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <div className={`flex flex-col items-start justify-start ${className}`}>
      {label && (
        <Typography variant="body2" className="mb-2 font-medium">
          {label}
        </Typography>
      )}
      <div
        className={`${
          !noOutline
            ? "border border-b5b5b5"
            : noOutline && error
            ? "border border-red-500"
            : "shadow-sm"
        } bg-white rounded-md h-10 flex items-center px-3 w-full ${
          error ? "border-red-500" : ""
        }`}
      >
        <input
          className="w-full bg-transparent focus:outline-none text-sm placeholder:text-gray-600"
          {...rest}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type="text"
        />
      </div>
      <motion.p
        variants={topBottomVariant}
        initial="hidden"
        animate={error ? "visible" : "hidden"}
        className="text-red-500 h-4 mt-1 text-xs overflow-y-hidden"
      >
        {error}
      </motion.p>
    </div>
  );
};

export default Input;
