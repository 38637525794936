import Banner from "../components/Banner";
import ReactGA from "react-ga4";
import Card from "../components/Card";
import Typography from "../widget/Typography";
import Input from "../widget/Input";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import BottomUpdatesBanner from "../components/BottomUpdatesBanner";
import BottomBanner from "../components/BottomBanner";
import Header from "../components/Header";
import Button from "../widget/Button";
import CustomCarousel from "../widget/CustomCarousel";
import { useContext, useState } from "react";
import Context from "../widget/Context";

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const { screenSize, NewsLetter } = useContext(Context);
  const [newsLetterEmail, setNewsLetterEmail] = useState();
  const [error, setError] = useState();

  const handleChange = (e) => {
    setNewsLetterEmail(e.target.value);
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (e.target.value === "") {
      setError("Email is required");
    } else if (!emailRegex.test(e.target.value)) {
      setError("Invalid email address");
    } else {
      setError(null);
    }
  };

  const handleClick = () => {
    if (newsLetterEmail) {
      setNewsLetterEmail("");
      NewsLetter(newsLetterEmail);
      ReactGA.event({
        category: "Button",
        action: "home/stayUpdatedWithOurNewsletter/submit",
        label: "Submit",
      });
      setError(null);
    } else {
      setError("Email is required");
    }
  };

  const bannerTitle = () => {
    if (screenSize?.width >= 1024) {
      return "Dataphion Hermes : Transforming/petabytes to insights. Your next-gen/data lakehouse solution awaits!";
    } else if (screenSize?.width >= 768) {
      return "Dataphion Hermes : Transforming/petabytes to insights. Your next-gen/data lakehouse solution awaits!";
    } else {
      return "Dataphion Hermes :/Transforming/petabytes to insights./Your next-gen/data lakehouse/solution awaits!";
    }
  };
  const bannerTitle2 = () => {
    if (screenSize?.width >= 1024) {
      return "Enhance your CRM's/performance with our/ScalerMax Database Solution";
    } else if (screenSize?.width >= 768) {
      return "Enhance your CRM's/performance with our/ScalerMax Database Solution";
    } else {
      return "Enhance your/CRM's performance/with/our ScalerMax/Database Solution";
    }
  };
  const bannerTitle3 = () => {
    if (screenSize?.width >= 1024) {
      return "Empowering Enterprises/Through Innovative Software,/Infrastructure and Data Services";
    } else if (screenSize?.width >= 768) {
      return "Empowering Enterprises/Through Innovative Software,/Infrastructure and Data Services";
    } else {
      return "Empowering/Enterprises/Through/Innovative/Software,/Infrastructure/and Data Services";
    }
  };

  const data_lakehouse_solution = [
    "Inbuilt Low-Code, No-Code Pipeline Builder",
    "Deployable on-prem and cloud",
    "Get started with ingesting data within minutes and not days",
    "Built on latest Open-source Technology",
  ];

  const scale_max_database_solution = [
    "Seamlessly scales during peak loads",
    "Automatically scales down during low traffic",
    "Optimized for major CMS systems such Magneto, Drupal, Strapi, Laravel, etc.,",
    "Business-Centric: Focus on customer needs, not on scaling issues",
  ];

  const buttonVariant = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      zIndex: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

  return (
    <div className="relative xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />

      <div className="relative">
        <CustomCarousel
          handleSlideChange={(index) => setCurrentSlide(index)}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          infiniteLoop={true}
          autoPlay
          centerMode
          interval="5000"
          centerSlidePercentage={100}
          automaticSliding
        >
          <Banner
            blurBackground
            textColor="text-white"
            className="bg-landing-page-background bg-cover bg-no-repeat	bg-center"
            fullHeight
            title={bannerTitle()}
            secondaryButton="Contact Us"
            onClickSecondary={() => {
              navigate(constants.route.ContactUs);
              ReactGA.event({
                category: "Button",
                action: "home/banner1/contactUs",
                label: "Contact Us",
              });
            }}
            primaryButton="Learn More"
            onClickPrimary={() => {
              navigate(constants.route.dataphionHermes);
              ReactGA.event({
                category: "Button",
                action: "home/banner1/learnMore",
                label: "Learn More",
              });
            }}
          />
          <Banner
            blurBackground
            textColor="text-white"
            className="bg-banner-image3 bg-cover bg-no-repeat	bg-center"
            fullHeight
            title={bannerTitle2()}
            secondaryButton="Contact Us"
            onClickSecondary={() => {
              navigate(constants.route.ContactUs);
              ReactGA.event({
                category: "Button",
                action: "home/banner2/contactUs",
                label: "Contact Us",
              });
            }}
            primaryButton="Learn More"
            onClickPrimary={() => {
              navigate(constants.route.scalerMaxDatabaseSolution);
              ReactGA.event({
                category: "Button",
                action: "home/banner2/learnMore",
                label: "Learn More",
              });
            }}
          />
          <Banner
            blurBackground
            textColor="text-white"
            className="bg-banner-image4 bg-cover bg-no-repeat	bg-left"
            fullHeight
            title={bannerTitle3()}
            secondaryButton="Contact Us"
            onClickSecondary={() => {
              navigate(constants.route.ContactUs);
              ReactGA.event({
                category: "Button",
                action: "home/banner3/contactUs",
                label: "Contact Us",
              });
            }}
            primaryButton="Learn More"
            onClickPrimary={() => {
              navigate(constants.route.services);
              ReactGA.event({
                category: "Button",
                action: "home/banner3/learnMore",
                label: "Learn More",
              });
            }}
          />
        </CustomCarousel>
        <div className="flex w-full items-center justify-between absolute top-1/2 px-3">
          <div
            className="flex items-center justify-center bg-white bg-opacity-10 text-grey-400 rounded-full h-10 w-10 cursor-pointer"
            onClick={() => {
              setCurrentSlide(currentSlide - 1);
              ReactGA.event({
                category: "Button",
                action: "home/banner3/previous",
                label: "Previous",
              });
            }}
          >
            <i className="fa-solid fa-angle-left text-md"></i>
          </div>
          <div
            className="flex items-center justify-center bg-white bg-opacity-10 text-grey-400 rounded-full h-10 w-10 cursor-pointer"
            onClick={() => {
              setCurrentSlide(currentSlide + 1);
              ReactGA.event({
                category: "Button",
                action: "home/banner3/next",
                label: "Next",
              });
            }}
          >
            <i className="fa-solid fa-angle-right text-md"></i>
          </div>
        </div>
      </div>
      <Card
        className="bg-card-background bg-cover bg-right bg-no-repeat"
        title="Data Lakehouse Solution"
        description="Revolutionize Data Management with Our Cutting-Edge Data Lakehouse Solution."
        reverse
        image={constants.images.multi_cloud}
      >
        <div className="grid grid-cols-1 gap-6">
          {data_lakehouse_solution.map((item, index) => (
            <Typography
              key={index}
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="subtitle2"
              className="font-semibold flex text-sm"
            >
              {item}
            </Typography>
          ))}
          <motion.div
            variants={buttonVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className="flex mt-4 space-x-2"
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(constants.route.dataphionHermes);
                ReactGA.event({
                  category: "Button",
                  action: "home/dataLakehouseSolution/learnMore",
                  label: "Learn More",
                });
              }}
            >
              Learn More
            </Button>
          </motion.div>
        </div>
      </Card>
      <Card
        className="bg-f6faff"
        title="ScalerMax Database Solution"
        description="Unlock Infinite Scalability and Cost-Efficiency for Your CRM Systems."
        image={constants.images.scaler_max_database_solution}
      >
        <div className="grid grid-cols-1 gap-6">
          {scale_max_database_solution.map((item, index) => (
            <Typography
              key={index}
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="subtitle2"
              className="font-semibold flex text-sm"
            >
              {item}
            </Typography>
          ))}
          <motion.div
            variants={buttonVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className="flex mt-4 space-x-2"
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(constants.route.scalerMaxDatabaseSolution);
                ReactGA.event({
                  category: "Button",
                  action: "home/scalerMaxDatabaseSolution/learnMore",
                  label: "Learn More",
                });
              }}
            >
              Learn More
            </Button>
          </motion.div>
        </div>
      </Card>
      <Card
        className="bg-card-background2 bg-cover bg-left bg-no-repeat"
        title="Our Services"
        description="Custom Solutions Tailored to Your Enterprise Needs."
        image={constants.images.our_services}
        reverse
      >
        <Typography
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          variant="h5"
          className="bg-blue-gradient text-transparent bg-clip-text text-md"
        >
          In Partnership with Microsoft Azure
        </Typography>
        <div className="grid grid-cols-1 gap-6 mt-6">
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            Integration of LLM into Enterprise Products
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            Enterprise Software Development
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            Apache Spark, Data Lake & Warehouse Consulting
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            Azure DevOps Consulting
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            Cloud Migration
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            DevOps & SRE
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="font-semibold flex text-sm"
          >
            Mobile App Development
          </Typography>
          <motion.div
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            className="flex mt-4 space-x-2"
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(constants.route.services);
                ReactGA.event({
                  category: "Button",
                  action: "home/ourServices/services",
                  label: "Services",
                });
              }}
            >
              Services
            </Button>
          </motion.div>
        </div>
      </Card>

      <div className="relative mt-32">
        <div className="absolute lg:px-16 md:px-10 xs:px-5 w-full lg:-top-32 md:-top-32 xs:-top-44">
          <BottomBanner
            className="bg-default-banner bg-center bg-cover bg-no-repeat"
            title="Stay Updated with Our Newsletter"
            subTitle="Subscribe to our newsletter for the latest and industry news."
          >
            <div className="flex md:space-x-2 lg:space-x-2 md:w-3/4 lg:w-3/4 xs:flex-col md:flex-row lg:flex-row xs:w-full">
              <Input
                noOutline
                error={error}
                placeholder="Enter your email"
                value={newsLetterEmail ?? ""}
                onChange={handleChange}
                className="lg:w-96 xs:w-full md:w-96 drop-shadow-md"
              />
              <Button
                variant="contained"
                type="button"
                className="!h-10 lg:mt-0 md:mt-0 xs:mt-4"
                onClick={handleClick}
              >
                Submit
              </Button>
            </div>
          </BottomBanner>
        </div>
        <BottomUpdatesBanner
          className="lg:!pt-44 xs:!pt-52 "
          title="Explore Our Specialized Services and Products for Your Business"
          description="Let's Build the Future, Together. Contact Us to Learn How Our Specialized Services and Products Can Benefit Your Business"
          primaryButton="Contact Us"
          secondaryButton="Request Demo"
          onPrimaryButtonClick={() => {
            navigate(constants.route.ContactUs);
            ReactGA.event({
              category: "Button",
              action: "home/bottomBanner/contactUs",
              label: "Contact Us",
            });
          }}
        />
      </div>
    </div>
  );
};

export default Home;
