const Image = ({ alt, src, className, height, width, ...rest }) => {
  if (src === undefined) {
    return (
      <div
        className={`bg-grey-100 flex items-center justify-center py-20 ${className}`}
      >
        <i className="fa-solid fa-image rounded-sm text-grey-400 text-6xl"></i>
      </div>
    );
  } else {
    return (
      <img
        className={`object-cover object-center w-full ${className}`}
        height={height}
        width={width}
        {...rest}
        src={
          src?.startsWith("http://") || src?.startsWith("https://")
            ? src
            : process.env.PUBLIC_URL + src
        }
        alt={alt ?? "Dataphion Images"}
      />
    );
  }
};

export default Image;
