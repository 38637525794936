import React from "react";
import Typography from "../widget/Typography";
import Button from "../widget/Button";
import Image from "../widget/Image";
import constants from "../constants";
import { motion } from "framer-motion";

const OutlinedCard = ({
  title,
  description,
  image,
  className,
  extraText,
  primaryButton,
  onPrimaryButtonClick,
  secondaryButton,
  onSecondaryButtonClick,
  imageClassName,
}) => {
  return (
    <div
      className={`w-full grid lg:grid-cols-2 xs:grid-cols-1 border bg-white border-secondary rounded-xl ${className}`}
    >
      <motion.div className="lg:p-10 md:p-10 xs:p-6 flex flex-col justify-center">
        <Typography
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          variant="h2"
          className="text-headingColor"
        >
          {title}
        </Typography>
        <Typography
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          variant="body1"
          className="mt-4 text-bodyColor"
        >
          {description}
        </Typography>
        {extraText}
        <motion.div
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          className="flex space-x-2 mt-6"
        >
          <Button variant="contained" onClick={onPrimaryButtonClick}>
            {primaryButton ?? "Contact Us"}
          </Button>
          <Button variant="outlined" onClick={onSecondaryButtonClick}>
            {secondaryButton ?? "Request"}
          </Button>
        </motion.div>
      </motion.div>
      <Image
        className={`w-full h-full w-full lg:rounded-r-xl lg:rounded-b-none md:rounded-b-xl xs:rounded-b-xl ${imageClassName}`}
        src={image}
        alt={`Dataphion ${title}`}
      />
    </div>
  );
};

export default OutlinedCard;
