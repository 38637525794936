import React, { useContext, useState } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import Card from "../components/Card";
import InternalCard from "../components/InternalCard";
import constants from "../constants";
import Typography from "../widget/Typography";
import { motion } from "framer-motion";
import OutlinedCard from "../components/OutlinedCard";
import Button from "../widget/Button";
import { useNavigate } from "react-router";
import Banner from "../components/Banner";
import Context from "../widget/Context";

const DataphionHermes = () => {
  const [hover, setHover] = useState(null);
  const navigate = useNavigate();
  const { screenSize } = useContext(Context);
  const bannerTitle = () => {
    if (screenSize?.width >= 1024) {
      return "Unleash the Power/of Your Data with/Dataphion Hermes";
    } else if (screenSize?.width >= 768) {
      return "Unleash the Power/of Your Data with/Dataphion Hermes";
    } else {
      return "Unleash the/Power of Your/Data with/Dataphion/Hermes";
    }
  };

  const cardItemVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      zIndex: 1,
      transition: {
        duration: 0.6,
        type: "spring",
        damping: 8,
        stiffness: 70,
      },
    },
  };

  const pricing = [
    {
      price: "$3000",
      type: "Basic Plan",
      icon: "fa-solid fa-cube",
      offers: ["5 Pipelines", "100 GB Data"],
    },
    {
      price: "$5000",
      type: "Standard Plan",
      icon: "fa-solid fa-plane",
      offers: ["10 Pipelines", "10 TB Data"],
    },
    {
      price: "$10000",
      type: "Extended Plan",
      icon: "fa-regular fa-gem",
      offers: ["15 Pipelines", "1 PB Data"],
    },
  ];
  return (
    <div className="xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <Banner
        blurBackground
        textColor="!text-white"
        fullHeight
        className="bg-dataphion-hermes-background bg-cover bg-no-repeat	bg-center"
        title={bannerTitle()}
        subTitle="Revolutionize your data architecture with Dataphion Hermes which is
            an end-to-end data lifecycle management product, complete with a
            low-code, no-code pipeline builder. Offering seamless deployment
            options for on-premises or cloud environments, our solution is the
            answer to your data management challenges."
        description="Data management is about to get a lot simpler. Dataphion Hermes
            enables enterprise to architect, design and implement their Data
            Lake & Warehouse solutions within minutes. It is built on top of
            open data format using Apache Iceberg and execution engine using
            Apache Spark. It is a fully managed solution that can be deployed
            on-premises or on the cloud. It is a low-code, no-code solution that
            can be used by data engineers, data scientists and data analysts to
            build their data pipelines and data marts."
        primaryButton="Contact Us"
        onClickPrimary={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "scalerMax/banner/contactUs",
            label: "Contact Us",
          });
        }}
      />
      <Card
        className="bg-card-background2 bg-cover bg-left bg-no-repeat"
        title="Effortless Data Pipeline Deployment"
        description="Low-Code, No-Code Pipeline Builder: Deploy complex data pipelines without a line of code, saving you valuable time and resources."
        image={constants.images.saving_valuable_time}
      >
        <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <InternalCard
            title="Problem Solved"
            subTitle="Forget the complexities and time-drain of manual coding."
            titleSize="h6"
            iconImage={constants.images.problem_icon}
            isBtnNotShow
          />
          <InternalCard
            title="Benefit"
            subTitle="Deploy data pipelines within minutes, not days."
            titleSize="h6"
            iconImage={constants.images.benefit_icon}
            isBtnNotShow
          />
        </div>
      </Card>
      <Card
        className="bg-f6faff"
        reverse
        title="Flexible Deployment Options to Suit Your Infrastructure"
        description="Multi-Environment Deployment: Whether you’re a cloud-first enterprise or relying on on-premises infrastructure, our solution is fully adaptable to your needs."
        image={constants.images.multi_environment_development}
      >
        <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <InternalCard
            iconImage={constants.images.problem_icon}
            title="Problem Solved"
            subTitle="Struggling with piecemeal solutions for cloud and on-premises data?"
            titleSize="h6"
            isBtnNotShow
          />
          <InternalCard
            iconImage={constants.images.benefit_icon}
            title="Benefit"
            subTitle="Seamlessly shift between or integrate cloud and on-prem environments. Simple centralized control plane to manage data and compute across multiple cloud providers."
            titleSize="h6"
            isBtnNotShow
          />
        </div>
      </Card>
      <Card
        className="bg-card-background bg-cover bg-right bg-no-repeat"
        title="Data Governance"
        description="Full Compliance and Security - Strict adherence to industry security standards, ensuring your data is always protected."
        image={constants.images.data_governance}
      >
        <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <InternalCard
            iconImage={constants.images.problem_icon}
            title="Problem Solved"
            subTitle="Concerns over compliance, security, and data quality have long plagued traditional data solutions."
            titleSize="h6"
            isBtnNotShow
          />
          <InternalCard
            iconImage={constants.images.benefit_icon}
            title="Benefit"
            subTitle="Achieve total compliance with a platform designed around strict industry standards."
            titleSize="h6"
            isBtnNotShow
          />
        </div>
      </Card>
      <motion.div
        className="lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16 bg-contact-us-banner bg-auto bg-center bg-no-repeat bg-f6faff"
        variants={constants.animation.cardsContainerVariant}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        <div className="flex flex-col justify-center items-center">
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body1"
            className="font-medium mb-2 text-tagColor text-center"
          >
            Pricing
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="h3"
            className="text-headingColor lg:w-1/2 xs:w-full text-center"
          >
            Transparent Pricing Structure for Pipeline and Data Management
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body1"
            className="my-4 text-bodyColor lg:w-1/2 xs:w-full text-center"
          >
            Simple and straightforward pricing with no hidden cost. Pricing is
            based on the number of pipelines and Data being managed. All other
            features are available across different tiers.
          </Typography>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-16 mt-16">
          {pricing.map((item, index) => (
            <motion.div
              variants={cardItemVariants}
              key={index}
              className="rounded-xl overflow-hidden shadow-xl bg-white"
              onHoverStart={() => setHover(index)}
              onHoverEnd={() => setHover(null)}
            >
              <div className="bg-blue-50 p-8 flex items-center w-full">
                <div className="bg-white shadow-md rounded-md overflow-hidden h-16 w-16 flex items-center justify-center relative">
                  <i
                    className={`${item?.icon} z-10 ${
                      hover === index ? "text-white" : "text-black"
                    } text-3xl`}
                  />
                  <motion.div
                    initial={{ height: "0px" }}
                    animate={{ height: hover === index ? "100%" : "0px" }}
                    transition={{ duration: 0.4 }}
                    className="absolute bottom-0 h-full w-full bg-blue-gradient"
                  />
                </div>
                <div className="ml-8">
                  <Typography variant="h6" className="text-primaryColor mb-1">
                    {item?.type}
                  </Typography>
                  <Typography variant="h4" className="text-primaryColor">
                    {item.price}/Month
                  </Typography>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 p-6">
                {item?.offers?.map((item, index) => (
                  <Typography
                    key={index}
                    variant="subtitle2"
                    className="text-bodyColor text-center"
                  >
                    {item}
                  </Typography>
                ))}
                <Button
                  onClick={() => {
                    navigate(constants.route.ContactUs);
                    ReactGA.event({
                      category: "Button",
                      action: `dataphionHermes/pricing/ContactUs`,
                      label: "Contact Us",
                    });
                  }}
                  className="mt-2"
                  variant="contained"
                >
                  Contact Us
                </Button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
      <div className="bg-card-background3 bg-cover bg-center bg-no-repeat">
        <Card
          title="Technical Highlights:"
          image={constants.images.technical_highlights}
          reverse
        >
          <div className="grid grid-cols-1 gap-6">
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="text-bodyColor"
            >
              <b>Data Versioning :</b> Maintain complete historical records with
              our robust data versioning features.
            </Typography>
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="text-bodyColor"
            >
              <b>Real-time Analytics :</b> In-built support for real-time
              analytics enabling instantaneous decision-making.
            </Typography>
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="text-bodyColor"
            >
              <b>High Availability & Disaster Recovery :</b> Built-in HA and DR
              capabilities to ensure uninterrupted service.
            </Typography>
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="text-bodyColor"
            >
              <b>Ingestion :</b> Batch and Realtime
            </Typography>
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="text-bodyColor"
            >
              <b>Security :</b> Avoid bringing in new Authentication systems and
              integrate directly with the enterprise AD systems.
            </Typography>
          </div>
        </Card>
        <div className="lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16">
          <OutlinedCard
            title="Request Your Free Demo Now"
            description="Talk to Our Industry Experts and Start Your Journey Towards
                Operational Excellence."
            extraText={
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="body1"
                className="mt-4 text-bodyColor"
              >
                You don’t have to navigate the complex landscape of data
                management and enterprise software alone. Our team of
                experienced professionals is here to guide you every step of the
                way.
              </Typography>
            }
            image={constants.images.talk_to_our_industry_experts}
            onPrimaryButtonClick={() => {
              navigate(constants.route.ContactUs);
              ReactGA.event({
                category: "Button",
                action: "dataphionHermes/requestYourFreeDemo/contactUs",
                label: "Contact us",
              });
            }}
            onSecondaryButtonClick={() => {
              navigate(constants.route.ContactUs);
              ReactGA.event({
                category: "Button",
                action: "dataphionHermes/requestYourFreeDemo/request",
                label: "Request",
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DataphionHermes;
