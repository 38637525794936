import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import constants from "../constants";
import Banner from "../components/Banner";
import { motion } from "framer-motion";
import Pagination from "../widget/Pagination";
import BlogsCard from "../components/BlogsCard";
import Context from "../widget/Context";
import Typography from "../widget/Typography";
import Button from "../widget/Button";
import Sidebar from "../components/Sidebar";
import Input from "../widget/Input";

const Blog = () => {
  const navigate = useNavigate();
  const {
    getBlogs,
    blogData,
    setShowSidebar,
    getBlogCategories,
    blogCategories,
  } = useContext(Context);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(6);
  const [search, setSearch] = useState();

  const bannerTitle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) {
      return "Our Blogs";
    } else if (screenWidth >= 768) {
      return "Our Blogs";
    } else {
      return "Our Blogs";
    }
  };

  useEffect(() => {
    getBlogCategories();
  }, []);

  useEffect(() => {
    getBlogs({ page, rowsPerPage });
  }, [page]);

  return (
    <div className="relative xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <Banner
        className="bg-F3F7FD !py-10"
        title={bannerTitle()}
        subTitle="Regularly updated blog posts on software development, technology trends, and industry insights"
        primaryButton="Contact Us"
        onClickPrimary={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "blogs/banner/contactUs",
            label: "Contact Us",
          });
        }}
      />
      <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-10 w-full lg:p-16 md:p-10 xs:p-5 ">
        <motion.div
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          className="flex justify-between items-center lg:col-span-3 md:col-span-2 xs:col-span-1 cursor-pointer"
        >
          <Typography className="text-headingColor truncate" variant="h4">
            All Blog Posts
          </Typography>
          <div className="flex space-x-2">
            <Button
              variant="outlined"
              onClick={() => getBlogs({ page, rowsPerPage })}
            >
             Clear All
            </Button>
            <Button
              variant="contained"
              startIcon={<i className="fa-solid fa-filter text-md" />}
              onClick={() => setShowSidebar(true)}
            >
              Filter
            </Button>
          </div>
        </motion.div>
        {blogData?.map((blog) => (
          <BlogsCard
            key={blog?.id}
            id={blog?.id}
            title={blog?.title}
            description={blog?.description}
            tag={blog?.blog_category?.data?.attributes?.category}
            image={
              "https://tracker.dataphion.com" +
              blog?.images?.data?.[0]?.attributes?.url
            }
          />
        ))}
      </div>
      <Pagination
        rowsPerPage={rowsPerPage}
        totalPost={blogData?.length}
        setPage={setPage}
      />
      <Sidebar title="Filters">
        <Input
          placeholder="Search Categories..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {blogCategories
          ?.filter((val) => {
            return !search
              ? val.category
              : val.category.toLowerCase().includes(search.toLowerCase());
          })
          ?.map((item, index) => (
            <Typography
              key={index}
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="font-medium mb-1 cursor-pointer px-4 py-2 hover:bg-f6faff hover:text-249edc rounded-sm"
              onClick={() => {
                getBlogs({ page, rowsPerPage, category: item?.category });
                setShowSidebar(false);
              }}
            >
              {item.category}
            </Typography>
          ))}
      </Sidebar>
    </div>
  );
};

export default Blog;
