import React, { useContext } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import Typography from "../widget/Typography";
import Card from "../components/Card";
import constants from "../constants";
import ServiceCard from "../components/ServiceCard";
import { motion } from "framer-motion";
import MultiCards from "../components/MultiCards";
import BottomUpdatesBanner from "../components/BottomUpdatesBanner";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import Context from "../widget/Context";

const ScalerMaxDatabaseSolutionLearnMore = () => {
  const navigate = useNavigate();
  const { screenSize } = useContext(Context);
  const bannerTitle = () => {
    if (screenSize?.width >= 1024) {
      return "Introducing ScalerMax/Database Solution";
    } else if (screenSize?.width >= 768) {
      return "Introducing ScalerMax/Database Solution";
    } else {
      return "Introducing/ScalerMax/Database/Solution";
    }
  };

  const data = [
    {
      title: "Dynamic Scalability",
      description:
        "Experience real-time scalability to handle any load—no more bottlenecks during peak times.",
      icon: "fa-solid fa-scale-balanced",
    },
    {
      title: "Cost-Effectiveness",
      description:
        "Pay only for what you use, eliminating wasted resources and saving you money.",
      icon: "fa-solid fa-sack-dollar",
    },
    {
      title: "Simplified Management",
      description:
        "Say goodbye to complex configurations and dedicated teams; manage your scalable database effortlessly with ScalerMax.",
      icon: "fa-solid fa-users-gear",
    },
    {
      title: "Business-Centric Approach",
      description:
        "ScalerMax frees your team to focus on what truly matters—your customers and business growth. Instead of navigating the complexities of database management during high-traffic periods, let ScalerMax handle it for you.",
      icon: "fa-regular fa-handshake",
    },
  ];
  const data2 = [
    {
      title: "Detects Traffic Increase",
      description: "Intelligent algorithms detect increased load in real-time.",
      image: constants.images.detects_traffic_increase,
    },
    {
      title: "Automatically Scales Up",
      description:
        "Resources are allocated dynamically, ensuring consistent performance.",
      image: constants.images.automatically_scales_up,
    },
    {
      title: "Optimized Costs",
      description:
        "When traffic subsides, ScalerMax scales down automatically, reducing costs.",
      image: constants.images.reducing_cost,
    },
    {
      title: "Zero Downtime",
      description:
        "Achieve this entire cycle without a single moment of downtime or data loss.",
      image: constants.images.zero_downtime,
    },
  ];
  return (
    <div className="xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <Banner
        blurBackground
        textColor="text-white"
        fullHeight
        className="bg-scaler-max-background bg-cover bg-no-repeat	bg-center"
        title={bannerTitle()}
        subTitle="Enhance your CRM's performance with our ScalerMax Database Solution.
          Designed to scale infinitely during peak loads and scale down during
          low-traffic periods, our database solution is the perfect backend for
          CRM systems like Magento and Drupal."
        primaryButton="Contact Us"
        onClickPrimary={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "scalerMax/banner/contactUs",
            label: "Contact Us",
          });
        }}
      />
      <Card
        className="bg-f6faff"
        tag="The Pain Points"
        title="Traditional Database Scaling Challenges"
        image={constants.images.traditional_database_scaling}
        reverse
      >
        <div className="grid grid-cols-1 gap-6 mt-4">
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Limited Scalability : </b>
            Conventional databases often struggle to scale, impacting
            performance during peak times.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>High Costs :</b> More resources mean higher costs, even when they
            are not in use.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Complexity :</b> Managing a scalable database often requires
            dedicated teams and additional software, making it less agile and
            more expensive.
          </Typography>
        </div>
      </Card>
      <Card
        className="bg-card-background2 bg-cover bg-left bg-no-repeat"
        tag="The Pain Points"
        title="The Continuous Loop of Increasing Costs"
        image={constants.images.loop_of_increasing_costs}
      >
        <div className="grid grid-cols-1 gap-4 mt-4">
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 1 :</b> Traffic Increases.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 2 :</b> Database Struggles, Slows Down.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 3 :</b> Increase Resources (CPU, Memory, Storage).
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 4 :</b> Costs Skyrocket.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 5 :</b> Traffic Decreases, But Costs Remain High.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 6 :</b> Try to Scale Down, Face Downtime or Data Loss.
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle2"
            className="text-bodyColor"
          >
            <b>Phase 7 :</b> Loop Repeats.
          </Typography>
        </div>
      </Card>
      <Card
        className="bg-f6faff"
        tag="The Pain Points"
        title="Diverted Focus"
        image={constants.images.diverted_focus}
        reverse
      >
        <Typography
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          variant="subtitle2"
          className="text-bodyColor"
        >
          <b>Problem :</b> As business and traffic increase, companies must
          capitalize on fulfilling customer needs to sustain growth. But
          instead, they find themselves bogged down by the technical and
          financial burdens of scaling their production systems.
        </Typography>
      </Card>
      <Card
        className="bg-card-background bg-cover bg-right bg-no-repeat"
        tag="The Pain Points"
        title="Business-Centric Approach"
        image={constants.images.business_centric_approach}
      >
        <Typography
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          variant="subtitle2"
          className="text-bodyColor"
        >
          <b>Benefit :</b>ScalerMax frees your team to focus on what truly
          matters—your customers and business growth. Instead of navigating the
          complexities of database management during high-traffic periods, let
          ScalerMax handle it for you.
        </Typography>
      </Card>
      <div />
      <div className="lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16 relative bg-f6faff">
        <motion.div
          className="flex justify-center items-center w-full"
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <Typography
            variant="h3"
            className="lg:w-3/4 md:w-3/4 xs:w-full xs:mb-6 bg-blue-gradient text-transparent bg-clip-text font-extrabold text-center"
          >
            The ScalerMax Solution
          </Typography>
        </motion.div>
        <motion.div
          variants={constants.animation.cardsContainerVariant}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          className="grid gap-x-12 gap-y-20 lg:grid-cols-2 xs:grid-cols-1 md:grid-cols-2 mt-20"
        >
          {data.map((item, index) => {
            return (
              <ServiceCard
                key={index}
                title={item?.title}
                subTitle={item?.description}
                icon={item?.icon}
                isBtnNotShow
              />
            );
          })}
        </motion.div>
        <img
          className="absolute bottom-0 w-1/2 left-0 -z-999"
          src={constants.images.bottomBackgroundImage}
          alt="Dataphion The ScalerMax Solution Background"
        />
        <img
          className="absolute bottom-0 w-1/2 right-0 -z-999"
          src={constants.images.bottomBackgroundImage}
          alt="Dataphion The ScalerMax Solution Background"
        />
      </div>
      <MultiCards title="How ScalerMax Works" data={data2} />
      <BottomUpdatesBanner
        title="Get Started With ScalerMax"
        description="Ready to break the continuous loop of high costs and limited scalability? Get started with ScalerMax today."
        primaryButton="Contact Us"
        secondaryButton="Request"
        onPrimaryButtonClick={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "ScalerMax/bottomBanner/contactUs",
            label: "Contact Us",
          });
        }}
      />
    </div>
  );
};

export default ScalerMaxDatabaseSolutionLearnMore;
