import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { motion } from "framer-motion";
import constants from "../constants";
import Image from "../widget/Image";
import TextArea from "../widget/TextArea";
import Button from "../widget/Button";
import Input from "../widget/Input";
import Typography from "../widget/Typography";
import Context from "../widget/Context";
import { useCallback } from "react";

const InquiryCard = ({
  className,
  imageClassName,
  image,
  tag,
  title,
  description,
}) => {
  const [contactDetails, setContactDetails] = useState({});
  const [errors, setErrors] = useState({});
  const { GetInTouch } = useContext(Context);

  const imageVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: 0.8,
      },
    },
  };

  const validateForm = useCallback(() => {
    const errors = {};
    if (Object.keys(contactDetails).length !== 0) {
      if (!contactDetails.name) {
        errors.name = "Name is required";
      } else if (!/^[A-Za-z\s]+$/.test(contactDetails.name)) {
        errors.name = "Please enter a valid name";
      } else if (/^\s/.test(contactDetails.name)) {
        errors.name = "Invalid name: No leading spaces allowed.";
      }
      if (!contactDetails.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contactDetails.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!contactDetails.phone) {
        errors.phone = "Phone number is required";
      } else if (!/^\d{8,10}$/.test(contactDetails.phone)) {
        errors.phone =
          "Please enter a valid contact number with 8 to 10 digits";
      }
      setErrors(errors);
      return Object.keys(errors).length === 0;
    }
  }, [contactDetails]);

  useEffect(() => {
    validateForm();
  }, [contactDetails, validateForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((values) => ({ ...values, [name]: value }));
  };

  return (
    <div className={`lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16 ${className}`}>
      <motion.div
        variants={constants.animation.bottomTopVariant}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className="flex flex-col text-center justify-center"
      >
        <Typography variant="body1" className="font-medium mb-6 text-tagColor">
          {tag}
        </Typography>
        <Typography
          variant="h1"
          className="md:w-full lg:w-full bg-blue-gradient text-transparent bg-clip-text font-extrabold mb-6"
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className="mb-8 md:w-4/5 lg:w-full text-bodyColor"
        >
          {description}
        </Typography>
      </motion.div>
      <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 xs:grid-cols-1 bg-white drop-shadow-lg rounded-2xl overflow-hidden">
        <motion.div
          className="relative"
          variants={imageVariants}
          initial="hidden"
          animate="visible"
        >
          <Image
            className={`w-full h-full ${imageClassName}`}
            src={image}
            alt={`Dataphion ${title}`}
          />
          <motion.div
            className="absolute bottom-0 w-full flex items-center justify-center flex-col p-14 bg-gradient-to-t from-black"
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <i className="fa-regular fa-envelope bg-clip-text text-transparent bg-blue-gradient mb-4 lg:text-5xl md:text-5xl xs:text-2xl" />
            <div className="text-white  lg:text-2xl md:text-2xl xs:text-md">
              connect@dataphion.com
            </div>
          </motion.div>
        </motion.div>
        <motion.div
          className="lg:p-14 md:p-10 xs:p-6 flex flex-col"
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <div className="space-y-1">
            <Input
              name="name"
              label="Name"
              value={contactDetails?.name ?? ""}
              onChange={handleChange}
              error={errors.name}
            />
            <Input
              name="phone"
              value={contactDetails?.phone ?? ""}
              label="Contact Number"
              onChange={handleChange}
              error={errors.phone}
            />
            <Input
              name="email"
              value={contactDetails?.email ?? ""}
              label="Email"
              onChange={handleChange}
              error={errors.email}
            />
            <TextArea
              name="message"
              value={contactDetails?.message ?? ""}
              label="Message"
              onChange={handleChange}
              error={errors.message}
            />
            <Button
              variant="contained"
              disabled={
                Object.keys(errors).length !== 0 ||
                Object.keys(contactDetails).length === 0
              }
              onClick={() => {
                ReactGA.event({
                  category: "Button",
                  action: "contactUs/getInTouch/submit",
                  label: "Submit",
                });
                GetInTouch(contactDetails);
                setContactDetails({});
              }}
            >
              Submit
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default InquiryCard;
