import React, { useEffect, useState } from "react";
import axios from "axios";
import constants from "../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const qs = require("qs");

export const Context = React.createContext({});

export const Provider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [moreMenu, setMoreMenu] = useState(false);
  const [blogData, setBlogData] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [blogCategories, setBlogCategories] = useState();
  const [loading, setLoading] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GetInTouch = async (props) => {
    const contactData = {
      name: props?.name,
      email: props?.email,
      contact_number: props?.phone,
      message: props?.message,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.contactUs}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ data: contactData }),
      };
      const elements_response = await axios(elements_req_obj);
      if (
        [200, 201].includes(elements_response.status) &&
        elements_response.data
      ) {
        toast.success(
          "Thank you for contacting us! We'll get back to you as soon as possible.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const NewsLetter = async (email) => {
    const newLetterData = {
      email: email,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.newsLatter}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ data: newLetterData }),
      };
      const elements_response = await axios(elements_req_obj);
      if (
        [200, 201].includes(elements_response.status) &&
        elements_response.data
      ) {
        toast.success("Thank you for subscribing to Dataphion newsletter!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getBlogs = async (props) => {
    const query = qs.stringify(
      {
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        populate: "*",
        ...(props.id ||
          (props.category && {
            filters: {
              ...(props.id
                ? {
                    id: {
                      $in: props.id,
                    },
                  }
                : props.category
                ? {
                  blog_category: {
                      category:{
                        $in: props.category,
                      }
                    },
                  }
                : {}),
            },
          })),
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: props.id
          ? constants.url.blogs + `?${query}`
          : constants.url.blogs + `?${query}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setBlogData(
          response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          }))
        );
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getBlogCategories = async (props) => {
    const query = qs.stringify(
      {
        pagination: {
          limit: -1,
        },
        fields: ["category"],
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.blog_category + `?${query}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setBlogCategories(
          response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          }))
        );
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Context.Provider
      value={{
        GetInTouch,
        NewsLetter,
        isMenuOpen,
        setIsMenuOpen,
        moreMenu,
        setMoreMenu,
        screenSize,
        getBlogs,
        blogData,
        loading,
        showSidebar,
        setShowSidebar,
        blogCategories,
        getBlogCategories,
      }}
    >
      {children}

      <ToastContainer className="!w-fit" autoClose={1000} />
    </Context.Provider>
  );
};

export default Context;
