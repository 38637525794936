const host = "https://tracker.dataphion.com/api";

const constants = {
  url: {
    contactUs: host + "/contact-uses",
    newsLatter: host + "/newsletters",
    blogs: host + "/blogs",
    blog_category:host+'/blog-categories'
  },

  route: {
    default: "/",
    pageNotFound: "/404",
    home: "/home",
    aboutUs: "/about-us",
    services: "/services",
    ContactUs: "/contact-us",
    scalerMaxDatabaseSolution: "/scaler-max",
    dataphionHermes: "/dataphion-hermes",
    privacyPolicy: "/privacy-policy",
    blogs: "/blogs",
  },

  string: {},

  css: {},

  images: {
    //Header Images
    company_logo: process.env.PUBLIC_URL + "/dataphion.png",

    //Home Images
    multi_cloud: process.env.PUBLIC_URL + "assets/images/home/multi_cloud.jpg",
    scaler_max_database_solution:
      process.env.PUBLIC_URL +
      "/assets/images/home/scaler_max_database_solution.jpg",
    our_services:
      process.env.PUBLIC_URL + "/assets/images/home/our_services.jpg",

    //Dataphion Hermes Images
    data_management:
      process.env.PUBLIC_URL +
      "/assets/images/dataphion_hermes/data_management.jpg",
    saving_valuable_time:
      process.env.PUBLIC_URL +
      "/assets/images/dataphion_hermes/saving_valuable_time.jpg",
    multi_environment_development:
      process.env.PUBLIC_URL +
      "/assets/images/dataphion_hermes/multi_environment_development.jpg",
    data_governance:
      process.env.PUBLIC_URL +
      "/assets/images/dataphion_hermes/data_governance.jpg",
    technical_highlights:
      process.env.PUBLIC_URL +
      "/assets/images/dataphion_hermes/technical_highlights.jpg",
    talk_to_our_industry_experts:
      process.env.PUBLIC_URL +
      "/assets/images/dataphion_hermes/talk_to_our_industry_experts.jpg",

    //Scaler Max Images
    traditional_database_scaling:
      process.env.PUBLIC_URL +
      "/assets/images/scaler_max/traditional_database_scaling.jpg",
    loop_of_increasing_costs:
      process.env.PUBLIC_URL +
      "/assets/images/scaler_max/loop_of_increasing_costs.jpg",
    diverted_focus:
      process.env.PUBLIC_URL + "/assets/images/scaler_max/diverted_focus.jpg",
    business_centric_approach:
      process.env.PUBLIC_URL +
      "/assets/images/scaler_max/business_centric_approach.jpg",
    detects_traffic_increase:
      process.env.PUBLIC_URL +
      "/assets/images/scaler_max/detects_traffic_increase.jpg",
    automatically_scales_up:
      process.env.PUBLIC_URL +
      "/assets/images/scaler_max/automatically_scales_up.jpg",
    reducing_cost:
      process.env.PUBLIC_URL + "/assets/images/scaler_max/reducing_cost.jpg",
    zero_downtime:
      process.env.PUBLIC_URL + "/assets/images/scaler_max/zero_downtime.jpg",

    //Services
    introduction:
      process.env.PUBLIC_URL + "assets/images/service/introduction.jpg",
    integration_of_llm:
      process.env.PUBLIC_URL + "assets/images/service/integration_of_llm.jpg",
    enterprise_software_development_service:
      process.env.PUBLIC_URL +
      "assets/images/service/enterprise_software_development.jpg",
    mobile_app_development_service:
      process.env.PUBLIC_URL +
      "assets/images/service/mobile_app_development.jpg",
    streamline_devops:
      process.env.PUBLIC_URL + "assets/images/service/streamline_devops.jpg",
    warehouse_consulting:
      process.env.PUBLIC_URL + "assets/images/service/warehouse_consulting.jpg",

    //About Images
    about_technology:
      process.env.PUBLIC_URL + "assets/images/about/technology.jpg",
    history: process.env.PUBLIC_URL + "assets/images/about/history.jpg",
    enterprise_software_development:
      process.env.PUBLIC_URL +
      "assets/images/about/enterprise_software_development.jpg",
    mobile_app_development:
      process.env.PUBLIC_URL + "assets/images/about/mobile_app_development.jpg",
    devOps_sre: process.env.PUBLIC_URL + "assets/images/about/devOps_sre.jpg",
    consulting_services:
      process.env.PUBLIC_URL + "assets/images/about/consulting_services.jpg",

    //Contact Us Images
    get_in_touch:
      process.env.PUBLIC_URL + "assets/images/contact/get_in_touch.jpg",

    //Blog Images
    blogs: process.env.PUBLIC_URL + "assets/images/blog/blogs.jpg",

    //Other Images
    bottomBackgroundImage:
      process.env.PUBLIC_URL + "/assets/images/background/bg2.png",
    ImageNotFound:
      process.env.PUBLIC_URL + "/assets/images/background/image-not-found.jpg",
    problem_icon: process.env.PUBLIC_URL + "/assets/icon/problem.svg",
    benefit_icon: process.env.PUBLIC_URL + "/assets/icon/benefit.svg",
    pageNotFound: process.env.PUBLIC_URL + "/not-found.svg",
  },

  animation: {
    bottomTopVariant: {
      offscreen: {
        y: 50,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        zIndex: 1,
        transition: {
          duration: 0.2,
        },
      },
    },
    topBottomVariant: {
      offscreen: {
        y: -50,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      },
    },
    rightLeftVariant: {
      offscreen: {
        x: 50,
        opacity: 0,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      },
    },
    leftRightVariant: {
      offscreen: {
        x: -50,
        opacity: 0,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      },
    },
    cardsContainerVariant: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          delayChildren: 0.7,
          staggerChildren: 0.2,
        },
      },
    },
  },
};

export default constants;
