import React from "react";
import { motion } from "framer-motion";
import Typography from "../widget/Typography";
import AnimatedTextCharacter from "./AnimatedTextCharacter";
import Button from "../widget/Button";

const Banner = ({
  tag,
  title,
  subTitle,
  isBtnNotShow,
  primaryButton,
  secondaryButton,
  onClickPrimary,
  onClickSecondary,
  className,
  fullHeight,
  textColor,
  description,
  blurBackground,
}) => {
  const buttonsVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: 1,
      },
    },
  };

  return (
    <div
      className={`w-full ${
        fullHeight
          ? "min-h-[calc(100vh-3.5rem)] lg:px-16 lg:py-0 md:px-10 xs:px-5 xs:py-5"
          : "lg:px-16 lg:py-28 md:px-10 xs:px-5 xs:py-16"
      } relative z-1 grid lg:grid-cols-2 md:grid-cols-2  xs:grid-cols-1 lg:gap-20 md:gap-10 xs:gap-5 ${className}`}
    >
      <div className="flex flex-col justify-center">
        {tag && (
          <Typography
            variant="body1"
            className="font-medium mb-5 text-tagColor z-50"
          >
            {tag}
          </Typography>
        )}
        <AnimatedTextCharacter textColor={textColor} text={title} />
        <motion.div
          initial={{ opacity: 0, zIndex: 99, y: -50 }}
          animate={{ opacity: 1, zIndex: 99, y: 0 }}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <Typography
            variant="body1"
            className={`mt-4 ${
              textColor ?? "text-bodyColor"
            } drop-shadow-lg text-left relative z-50`}
          >
            {subTitle}
          </Typography>
        </motion.div>
        {description && (
          <motion.div
            initial={{ opacity: 0, zIndex: 99, y: -50 }}
            animate={{ opacity: 1, zIndex: 99, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
          >
            <Typography
              variant="body1"
              className={`mt-6 ${
                textColor ?? "text-bodyColor"
              } drop-shadow-lg text-left relative z-50`}
            >
              {description}
            </Typography>
          </motion.div>
        )}

        {!isBtnNotShow && (
          <motion.div
            className="flex space-x-2 mt-6 relative z-50"
            variants={buttonsVariants}
            initial="hidden"
            animate="visible"
          >
            {primaryButton && (
              <Button variant="contained" onClick={onClickPrimary}>
                {primaryButton}
              </Button>
            )}
            {secondaryButton && (
              <Button variant="outlined" onClick={onClickSecondary}>
                {secondaryButton}
              </Button>
            )}
          </motion.div>
        )}
      </div>
      {blurBackground && (
        <div className="bg-gradient-to-r from-primary to-transparent w-full h-full absolute top-0" />
      )}
    </div>
  );
};

export default Banner;
