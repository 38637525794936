import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const AnimatedText = ({ className, text, isVisible, textColor }) => {
  const letters = Array.from(text);

  const container = {
    hidden: { opacity: 0 },
    visible: () => ({
      opacity: 1,
      transition: {
        staggerChildren: 0.02,
      },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      x: 0,
      rotate: 0,
      transition: {
        type: "linear",
      },
    },
    hidden: {
      opacity: 0,
      x: 200,
      rotate: 100,
      transition: {
        type: "linear",
      },
    },
  };

  return (
    <motion.div
      className={`flex ${className}`}
      variants={container}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
    >
      {letters.map((letter, index) => (
        <motion.span
          className={`${
            textColor ?? "text-headingColor"
          } lg:text-5xl xs:text-4xl font-bold`}
          variants={child}
          key={index}
        >
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

const AnimatedTextCharacter = ({ text, textColor }) => {
  const textArray = text.split("/" ?? "-" ?? "-");
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentTextIndex < textArray.length - 1) {
        setCurrentTextIndex(currentTextIndex + 1);
      }
    }, 700);

    return () => clearTimeout(timeout);
  }, [currentTextIndex, textArray]);

  return (
    <>
      {textArray.map((text, index) => (
        <AnimatedText
          className="mb-2 z-50"
          textColor={textColor}
          text={text}
          key={index}
          isVisible={index <= currentTextIndex}
        />
      ))}
    </>
  );
};

export default AnimatedTextCharacter;
