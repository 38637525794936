import React from "react";
import Typography from "../widget/Typography";
import constants from "../constants";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const imageVariants = {
    offscreen: {
      y: 10,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <motion.div
      className="w-full"
      variants={imageVariants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.6 }}
    >
      <div className="flex w-full lg:justify-between md:justify-between xs:flex-col-reverse lg:flex-row md:flex-row xs:justify-center xs:items-center bg-black py-6 px-20">
        <motion.div
          variants={constants.animation.leftRightVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <Typography variant="body2" className="text-white">
            © {new Date().getFullYear()} Dataphion. All rights reserved.
          </Typography>
        </motion.div>
        <motion.div
          variants={constants.animation.rightLeftVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          className="flex justify-between lg:w-1/4  md:w-1/2 xs:w-full xs:text-center  xs:flex-col lg:flex-row md:flex-row"
        >
          <Typography
            variant="body2"
            className="underline whitespace-nowrap cursor-pointer text-white hover:text-tagColor"
            onClick={() => navigate(constants.route.privacyPolicy)}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            className="underline whitespace-nowrap cursor-pointer text-white hover:text-tagColor"
          >
            Terms of Service
          </Typography>
          <Typography
            variant="body2"
            className="underline  whitespace-nowrap cursor-pointer text-white hover:text-tagColor"
          >
            Cookie Policy
          </Typography>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Footer;
