import React from "react";
import Typography from "../widget/Typography";
import { motion } from "framer-motion";
import constants from "../constants";

const BottomBanner = ({ title, subTitle, children, className }) => {
  return (
    <div
      className={`w-full grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-20 md:gap-10 xs:gap-5 lg:p-16 md:p-10 xs:p-5 rounded-xl  ${className} `}
    >
      <motion.div
        variants={constants.animation.bottomTopVariant}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <Typography className="text-white" variant="h3">
          {title}
        </Typography>
        <Typography variant="body1" className="mt-4 text-white">
          {subTitle}
        </Typography>
      </motion.div>
      <div className="w-full flex flex-col justify-center">{children}</div>
    </div>
  );
};

export default BottomBanner;
