import React from "react";
import Typography from "../widget/Typography";
import Button from "../widget/Button";
import { memo } from "react";
import { motion } from "framer-motion";
import constants from "../constants";

const InternalCard = ({
  title,
  subTitle,
  titleSize,
  className,
  learnMoreOnClick,
  isBtnNotShow,
  iconImage,
  primaryButtonName,
  buttonClassName,
}) => {
  return (
    <motion.div
      variants={constants.animation.bottomTopVariant}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.6 }}
    >
      <div className={`w-full flex ${className}`}>
        {iconImage && (
          <img
            className="w-6 h-6 rounded-md mr-2 mt-1"
            alt="Dataphion icons"
            src={iconImage}
          />
        )}
        <div className="">
          {title && (
            <Typography variant={`${titleSize ?? "h4"}`} className="text-black">
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography variant="body1" className="font-md text-sm">
              {subTitle}
            </Typography>
          )}
        </div>
        {!isBtnNotShow && (
          <div className={`flex mt-4 space-x-2 ${buttonClassName}`}>
            <Button variant="contained" onClick={learnMoreOnClick}>
              {primaryButtonName ?? "Learn More"}
            </Button>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default memo(InternalCard);
