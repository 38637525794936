import React, { useState } from "react";
import ReactGA from "react-ga4";
import { motion } from "framer-motion";
import Button from "../widget/Button";
import Typography from "../widget/Typography";
import constants from "../constants";
import { useNavigate } from "react-router-dom";

const BlogsCard = ({ title, description, tag, id, image }) => {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const childVariants = {
    hover: {
      scale: hoveredIndex === null ? 1 : 1.1,
    },
    initial: {
      scale: 1,
    },
  };

  return (
    <motion.div
      className="flex flex-col items-center overflow-hidden bg-white rounded-xl drop-shadow-md hover:drop-shadow-lg"
      onHoverStart={() => setHoveredIndex(1)}
      onHoverEnd={() => setHoveredIndex(null)}
    >
      <div className="lg:min-h-60 md:min-h-60 xs:min-h-40 w-full overflow-hidden">
        <motion.img
          src={image ?? constants.images.ImageNotFound}
          alt={`Dataphion ${title}`}
          className="lg:h-60 md:h-60 xs:h-40 w-full mb-8 overflow-hidden object-center object-cover"
          variants={childVariants}
          animate={hoveredIndex === 1 ? "hover" : "initial"}
          transition={{ duration: 0.3, delay: 0.1 }}
        />
      </div>
      <div className="flex flex-col justify-between py-8 px-6 w-full h-full">
        <div className="">
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body1"
            className="font-medium mb-2 text-tagColor"
          >
            {tag}
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            className="mb-2 text-headingColor"
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body2"
            className="font-semibold two-lines"
          >
            {description}
          </Typography>
        </div>

        <Button
          className="w-full"
          endIcon={<i className="fa-solid fa-angle-right text-xs" />}
          onClick={() => {
            navigate(constants.route.blogs + `/${id}`);
            ReactGA.event({
              category: "Button",
              action: `blogs/${title}/Read More`,
              label: "Contact Us",
            });
          }}
        >
          Read more
        </Button>
      </div>
    </motion.div>
  );
};

export default BlogsCard;
