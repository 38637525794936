import React from "react";
import Typography from "../widget/Typography";
import Image from "../widget/Image";
import { memo } from "react";
import { motion } from "framer-motion";
import constants from "../constants";

const Card = ({
  tag,
  title,
  description,
  children,
  image,
  className,
  noImage,
  reverse,
  player,
  url,
  imageClassName,
  titleFontSize,
}) => {
  const rightLeftVariant = {
    offscreen: {
      x: 50,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };
  const leftRightVariant = {
    offscreen: {
      x: -50,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <div
      className={`${className} lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16 relative`}
    >
      <div className="grid lg:grid-cols-2 md:grid-cols-2 xs:grid-cols-1 drop-shadow-2xl rounded-xl overflow-hidden bg-white min-h-416">
        <div
          className={`w-full flex flex-col justify-center px-12 py-10 ${
            reverse && "lg:order-last md:order-last"
          }`}
        >
          {tag && (
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="font-medium mb-2 text-tagColor"
            >
              {tag}
            </Typography>
          )}

          {title && (
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant={titleFontSize ?? "h3"}
              className="bg-blue-gradient text-transparent bg-clip-text font-extrabold"
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="mt-6 text-bodyColor"
            >
              {description}
            </Typography>
          )}
          {children && <div className="mt-6">{children}</div>}
        </div>
        {!noImage && (
          <motion.div
            className="w-full flex justify-center items-center"
            variants={reverse ? leftRightVariant : rightLeftVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <Image
              className={`w-full h-full ${imageClassName}`}
              src={image}
              alt={`DataPhion ${title}`}
            />
          </motion.div>
        )}
        {player && (
          <motion.div
            className="w-full"
            variants={reverse ? leftRightVariant : rightLeftVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <iframe
              width="100%"
              height="400"
              src={url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default memo(Card);
