const Button = ({
  className,
  disabled,
  variant,
  children,
  startIcon,
  endIcon,
  ...rest
}) => {
  let buttonClass = "";
  switch (variant) {
    case "contained":
      buttonClass =
        "bg-gradient-to-br from-249edc to-337CCF text-white hover:bg-gradient-to-tl";
      break;
    case "outlined":
      buttonClass =
        "bg-white bg-opacity-95 border-2 border-337CCF text-337CCF font-medium hover:text-white hover:bg-gradient-to-br from-249edc to-337CCF";
      break;
    default:
      buttonClass =
        "font-medium text-249edc hover:bg-337CCF hover:bg-opacity-20";
      break;
  }
  return (
    <button
      disabled={disabled}
      className={`flex items-center justify-center h-10 px-5 capitalize text-sm truncate rounded-md ${
        !disabled ? buttonClass : "bg-grey-500 text-white"
      } ${className}`}
      {...rest}
    >
      {startIcon && <span className="mr-2">{startIcon}</span>}
      {children}
      {endIcon && <span className="ml-2">{endIcon}</span>}
    </button>
  );
};

export default Button;
