import React, { useContext } from "react";
import constants from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../widget/Context";

const Header = () => {
  const { isMenuOpen, setIsMenuOpen } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const menuItems = [
    {
      id: 0,
      name: "Home",
      url: constants.route.default,
    },
    {
      id: 1,
      name: "Dataphion Hermes",
      url: constants.route.dataphionHermes,
    },
    {
      id: 2,
      name: "Scaler Max",
      url: constants.route.scalerMaxDatabaseSolution,
    },
    {
      id: 3,
      name: "Services",
      url: constants.route.services,
    },
    {
      id: 4,
      name: "About Us",
      url: constants.route.aboutUs,
    },
    {
      id: 5,
      name: "Contact Us",
      url: constants.route.ContactUs,
    },
    {
      id: 6,
      name: "Blogs",
      url: constants.route.blogs,
    },
  ];

  return (
    <header className="flex items-center justify-center bg-white border-b h-14 lg:px-16 xs:px-4 z-999 lg:sticky md:sticky xs:sticky top-0 w-full">
      <div className="flex items-center justify-between w-full">
        <img
          className="w-32 mr-16 cursor-pointer"
          onClick={() => navigate(constants.route.default)}
          src={constants.images.company_logo}
          alt="Dataphion Logo"
        />

        <div
          className={
            isMenuOpen
              ? "xs:block md:block absolute top-14 right-0 border-t w-full h-[calc(100vh-3.5rem)] bg-black bg-opacity-25 backdrop-blur-sm z-10 xs:overflow-hidden xs:overflow-hidden xs:overflow-y-auto"
              : "w-full xs:hidden md:hidden lg:block"
          }
        >
          <nav
            className={
              isMenuOpen
                ? "xs:block md:block absolute top-0 right-0 bg-white  w-full z-50 drop-shadow-xl"
                : "xs:hidden md:hidden lg:block lg:flex lg:items-center lg:w-4/5"
            }
          >
            {menuItems.map((item) => (
              <React.Fragment key={item.id}>
                <div
                  onClick={() => navigate(item?.url)}
                  className={`hover:text-249edc cursor-pointer p-2 mr-5 text-sm font-semibold truncate ${
                    location.pathname === item?.url
                      ? "text-249edc font-medium"
                      : (location.pathname === constants.route.default ||
                          location.pathname === constants.route.home) &&
                        item.name === "Home"
                      ? "text-249edc"
                      : "text-headingColor"
                  }`}
                >
                  {item.name}
                </div>
              </React.Fragment>
            ))}
          </nav>
          {isMenuOpen && (
            <div
              className="w-full h-full absolute top-0 left-0"
              onClick={toggleMenu}
            />
          )}
        </div>

        <div className="lg:hidden" onClick={toggleMenu}>
          <i
            className={`fa-solid h-8 w-8 flex items-center justify-center text-lg ${
              isMenuOpen ? "fa-xmark" : "fa-bars"
            }`}
          />
        </div>

        <div className="hidden lg:flex space-x-2 "></div>
      </div>
    </header>
  );
};

export default Header;
