import React, { useEffect, useRef, useState } from "react";
import Typography from "../widget/Typography";
import constants from "../constants";
import Header from "../components/Header";

const PrivacyPolicy = () => {
  const contentRef = useRef(null);
  const [activeHeading, setActiveHeading] = useState("");
  const headerHeight = 70;

  const privacyPolicyContent = [
    {
      title: "Introduction",
      content: [
        "Welcome to Dataphion's Privacy Policy. We respect your privacy and are committed to protecting your personal data. This policy will inform you of how we handle your data and tell you about your privacy rights.",
      ],
    },
    {
      title: "Information We Collect",
      content: [
        <b className="text-black">
          When you visit our website or use our services, we may collect the
          following data:,
        </b>,
        "Personal identification information (Name, email address, phone number, etc.),",
        "Usage data (e.g., pages visited, time spent on pages, click-throughs),",
        "Technical data (IP address, browser type, etc.)",
      ],
    },
    {
      title: "How We Collect Your Data",
      content: [
        <b className="text-black">We collect data through:</b>,
        "Direct interactions (e.g., when you sign up for our services or contact us),",
        "Automated tracking technologies (e.g., cookies, server logs)",
      ],
    },
    {
      title: "How We Use Your Data",
      content: [
        <b className="text-black">We use your data to:</b>,
        "Provide and improve our services,",
        "Respond to inquiries or support requests,",
        "Send promotional materials (if you've opted in),",
      ],
    },
    {
      title: "Data Storage & Security",
      content: [
        "Your data is stored securely within our internal CRMs. We have implemented security measures to ensure data integrity and prevent unauthorized access.",
      ],
    },
    {
      title: "Sharing of Data",
      content: [
        "We do not sell or share your personal data with third parties for marketing purposes. Data might be shared with partners or third-party providers only if necessary for our services or if mandated by law.",
      ],
    },
    {
      title: "Your Data Rights",
      content: [
        <b className="text-black">You have the right to:</b>,
        "Access the data we hold about you,",
        "Request correction of inaccurate data,",
        "Request deletion of your data,",
        "Object to or restrict processing,",
        "Request data transfer",
      ],
    },
    {
      title: "Cookies",
      content: [
        "Our website uses cookies to improve your experience. You can set your browser to refuse cookies or alert you when a cookie is being sent.",
      ],
    },
    {
      title: "Third-party Links",
      content: [
        "Our website may contain links to third-party websites. This policy does not cover these websites, and we are not responsible for their privacy practices.",
      ],
    },
    {
      title: "Changes to This Policy",
      content: [
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the 'Last updated' date.",
      ],
    },
    {
      title: "Contact Us",
      content: [
        "If you have any questions about this policy or our privacy practices, please contact us at contact@dataphion.com.",
      ],
    },
  ];

  useEffect(() => {
    if (activeHeading) {
      const targetHeading = contentRef.current.querySelector(
        `[data-heading="${activeHeading}"]`
      );

      if (targetHeading) {
        const offset = targetHeading.offsetTop - headerHeight;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [activeHeading]);

  return (
    <div className="xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <div className="grid grid-cols-4 gap-8 w-full lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16">
        <div
          className="lg:col-span-3 md:col-span-4 xs:col-span-4 grid grid-cols-1 gap-8"
          ref={contentRef}
        >
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="h3"
            className="bg-blue-gradient text-transparent bg-clip-text font-extrabold"
          >
            Privacy Policy
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body1"
          >
            Last updated: 22 September, 2023
          </Typography>

          {privacyPolicyContent.map((policy, index) => (
            <div
              key={index}
              className="grid grid-cols-1 gap-2"
              data-heading={policy.title}
            >
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="h6"
                className="bg-blue-gradient text-transparent bg-clip-text"
              >
                {index + 1}. {policy.title}
              </Typography>
              {policy.content.map((content) => (
                <Typography
                  variants={constants.animation.bottomTopVariant}
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.6 }}
                  variant="body1"
                  className="text-bodyColor"
                >
                  {content}
                </Typography>
              ))}
            </div>
          ))}
        </div>
        <div className="bg-f6faff rounded-md space-y-6 p-6 sticky h-fit top-36 lg:block md:hidden xs:hidden">
          {privacyPolicyContent.map((heading, index) => (
            <Typography
              key={index}
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="body1"
              className="cursor-pointer font-semibold hover:bg-blue-gradient hover:text-transparent hover:bg-clip-text"
              onClick={() => setActiveHeading(heading?.title)}
            >
              {heading?.title}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
