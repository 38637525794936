import React from "react";
import { motion } from "framer-motion";
import Typography from "../widget/Typography";
import Button from "../widget/Button";
import constants from "../constants";

const BottomUpdatesBanner = ({
  title,
  description,
  primaryButton,
  onPrimaryButtonClick,
  className,
}) => {
  return (
    <div
      className={`grid lg:grid-cols-2 md:grid-cols-2 xs:grid-cols-1 lg:gap-20 md:gap-10 xs:gap-5 lg:px-16 lg:py-20 md:p-10 xs:px-5 xs:py-16 bg-blue-50 bg-center bg-cover bg-no-repeat ${className}`}
    >
      {title && (
        <motion.div
          variants={constants.animation.leftRightVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          className="flex items-center justify-start"
        >
          <Typography variant="h3" className="text-black">
            {title}
          </Typography>
        </motion.div>
      )}
      <motion.div
        variants={constants.animation.rightLeftVariant}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.6 }}
        className="flex flex-col justify-center"
      >
        {description && (
          <Typography variant="body1" className="text-bodyColor font-medium">
            {description}
          </Typography>
        )}
        <div className="flex mt-6">
          <Button
            variant="contained"
            className="mr-4"
            onClick={onPrimaryButtonClick}
          >
            {primaryButton}
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

export default BottomUpdatesBanner;
