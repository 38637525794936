import React from "react";

const Skeleton = ({ row, className, type }) => {
  if (type === "image") {
    return (
      <div
        className={`w-full h-96 bg-gray skeleton rounded-sm mb-2 ${className}`}
      />
    );
  } else {
    return [...Array(row ?? 1)].map((_, index) => (
      <div
        key={index}
        className={`w-full h-6 bg-gray skeleton rounded-sm mb-2 ${className}`}
      />
    ));
  }
};

export default Skeleton;
