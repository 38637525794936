import Typography from "./Typography";
import { motion } from "framer-motion";

const TextArea = ({
  id,
  label,
  value,
  onChange,
  row,
  name,
  className,
  error,
}) => {
  const topBottomVariant = {
    hidden: {
      y: -4,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <div className={className}>
      <Typography variant="body2" className="mb-2 font-medium">
        {label}
      </Typography>
      <div className="border border-b5b5b5 bg-white rounded-md flex items-center p-2 w-full">
        <textarea
          className="w-full bg-transparent focus:outline-none text-sm"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          rows={row ?? 4}
        />
      </div>
      <motion.p
        variants={topBottomVariant}
        initial="hidden"
        animate={error ? "visible" : "hidden"}
        className="text-red-500 h-4 mt-1 text-xs overflow-y-hidden"
      >
        {error}
      </motion.p>
    </div>
  );
};

export default TextArea;
