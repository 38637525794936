import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const CustomCarousel = ({
  currentSlide,
  handleSlideChange,
  setCurrentSlide,
  carouselData,
  automaticSliding,
  centerSlidePercentage,
  children,
  ...rest
}) => {
  return (
    <>
      <div className="relative">
        <Carousel
          showThumbs={false}
          selectedItem={currentSlide}
          onChange={handleSlideChange}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          centerMode={true}
          preventMovementUntilSwipeScrollTolerance={true}
          centerSlidePercentage={centerSlidePercentage}
          {...rest}
        >
          {children}
        </Carousel>
        {!automaticSliding && (
          <div className="flex w-full items-center justify-between absolute top-1/2">
            <div
              className="flex items-center justify-center bg-blue-gradient text-white rounded-full h-10 w-10 cursor-pointer absolute -left-5"
              onClick={() => setCurrentSlide(currentSlide - 1)}
            >
              <i className="fa-solid fa-angle-left text-md"></i>
            </div>
            <div
              className="flex items-center justify-center bg-blue-gradient text-white rounded-full h-10 w-10 cursor-pointer absolute -right-5"
              onClick={() => setCurrentSlide(currentSlide + 1)}
            >
              <i className="fa-solid fa-angle-right text-md"></i>
            </div>
          </div>
        )}
      </div>
      {carouselData && (
        <div className="flex my-6 space-x-1 justify-center">
          {carouselData.map((_, index) => {
            return (
              <div
                key={index}
                className={`h-1 w-1 rounded-full ${
                  index === currentSlide ? "bg-primary" : "bg-grey-500"
                }  `}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default CustomCarousel;
