import React, { memo } from "react";
import ReactGA from "react-ga4";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import BottomUpdatesBanner from "../components/BottomUpdatesBanner";
import Banner from "../components/Banner";
import Header from "../components/Header";
import ServiceCard from "../components/ServiceCard";
import Typography from "../widget/Typography";
import MultiCards from "../components/MultiCards";

const About = () => {
  const navigate = useNavigate();

  const bannerTitle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) {
      return "Empowering Enterprises/Through Innovative/Software and Data Solutions";
    } else if (screenWidth >= 768) {
      return "Empowering Enterprises/Through Innovative/Software and Data Solutions";
    } else {
      return "Empowering/Enterprises Through/Innovative/Software and Data/Solutions";
    }
  };

  const data = [
    {
      title: "Enterprise Software Development",
      description: "Custom solutions built to drive operational efficiency.",
      image: constants.images.enterprise_software_development,
    },
    {
      title: "Mobile App Development",
      description:
        "Elevate your mobile strategy with our Flutter & React Native apps.",
      image: constants.images.mobile_app_development,
    },
    {
      title: "DevOps & SRE",
      description:
        "Implement modern operational practices for greater efficiency and reliability.",
      image: constants.images.devOps_sre,
    },
    {
      title: "Consulting Services",
      description:
        "Leverage our expertise in Apache Spark, Data Lakes, and Data Warehousing for informed decision-making.",
      image: constants.images.consulting_services,
    },
  ];

  const data1 = [
    {
      title: "Innovation",
      description:
        "We pride ourselves on pushing the boundaries of what's possible. Our Data Lakehouse Solution and ScalerMax Database Solution are prime examples of our innovative spirit.",
      icon: "fa-regular fa-lightbulb",
    },
    {
      title: "Expertise",
      description:
        "With years of industry experience, our team of experts specialize in Apache Spark, Data Lakes, Data Warehousing, and much more. Our staff is composed of certified professionals, ready to solve the most complex problems.",
      icon: "fa-solid fa-trophy",
    },
    {
      title: "Reliability",
      description:
        "Our partnership with Microsoft Azure stands as a testament to our reliability and commitment to quality. Azure's secure and scalable infrastructure enhances our capabilities, allowing us to provide you with reliable solutions.",
      icon: "fa-regular fa-handshake",
    },
    {
      title: "Customization",
      description:
        "We understand that every business is unique. That's why our range of services, from enterprise software development to mobile app creation, is fully customizable to meet your specific needs.",
      icon: "fa-solid fa-gears",
    },
  ];

  return (
    <div className="xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <Banner
        className="bg-F3F7FD"
        tag="Innovate"
        title={bannerTitle()}
        subTitle="We are technology company dedicated to providing cutting-edge solutions for businesses."
        primaryButton="Contact Us"
        onClickPrimary={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "aboutUs/banner/contactUs",
            label: "Contact Us",
          });
        }}
      />
      <div className="grid lg:grid-cols-2 md:grid-cols-2 xs:grid-cols-1 gap-4 lg:px-60 lg:py-20 xs:p-6 overflow-hidden bg-card-background3 bg-cover bg-center bg-no-repeat">
        <motion.img
          variants={constants.animation.leftRightVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          src={constants.images.about_technology}
          alt="Dataphion Technology"
          className="lg:rounded-tl-xl md:rounded-tl-xl xs:rounded-xl"
        />
        <motion.div
          variants={constants.animation.topBottomVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          className="flex flex-col justify-center items-center bg-f6faff p-6 lg:rounded-tl-xl md:rounded-tl-xl xs:rounded-xl"
        >
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="h3"
            className="bg-blue-gradient text-transparent bg-clip-text font-extrabold"
          >
            Technology
          </Typography>

          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body1"
            className="font-semibold mt-4"
          >
            At Dataphion company, we're not just another software solutions
            provider; we're your strategic partner in digital transformation.
            Partnered with industry leader Microsoft Azure, we leverage
            state-of-the-art technologies to deliver tailored solutions designed
            to propel your enterprise into the future.
          </Typography>
        </motion.div>
        <motion.div
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          className="flex flex-col justify-center items-center bg-gradient-to-br from-249edc to-337CCF text-white p-6 lg:rounded-tl-xl md:rounded-tl-xl xs:rounded-xl"
        >
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="h3"
            className="font-extrabold"
          >
            Our History
          </Typography>
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="body1"
            className="font-semibold mt-4"
          >
            Founded in 2018, Dataphion company has continually focused on
            innovation and excellence. From our beginnings as Technology
            Solutions Provider, we've expanded our services to become an
            all-encompassing solution for enterprise software needs.
          </Typography>
        </motion.div>
        <motion.img
          variants={constants.animation.rightLeftVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
          src={constants.images.history}
          alt="Dataphion History"
          className="lg:rounded-tl-xl md:rounded-tl-xl xs:rounded-xl"
        />
      </div>

      <div className="relative">
        <div className="lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16 relative bg-f6faff">
          <motion.div
            className="flex justify-center items-center w-full"
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Typography
              variant="h3"
              className="lg:w-3/4 md:w-3/4 xs:w-full xs:mb-6 bg-blue-gradient text-transparent bg-clip-text font-extrabold text-center"
            >
              Why Partner With Us?
            </Typography>
          </motion.div>
          <motion.div
            variants={constants.animation.cardsContainerVariant}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="grid gap-x-12 gap-y-20 lg:grid-cols-2 xs:grid-cols-1 md:grid-cols-2 mt-20"
          >
            {data1.map((item, index) => {
              return (
                <ServiceCard
                  key={index}
                  title={item?.title}
                  subTitle={item?.description}
                  icon={item?.icon}
                  isBtnNotShow
                />
              );
            })}
          </motion.div>
          <img
            className="absolute bottom-0 w-1/2 left-0 -z-999"
            src={constants.images.bottomBackgroundImage}
            alt="Dataphion Why Partner With Us"
          />
          <img
            className="absolute bottom-0 w-1/2 right-0 -z-999"
            src={constants.images.bottomBackgroundImage}
            alt="Dataphion Why Partner With Us"
          />
        </div>
        <MultiCards
          className="b-20"
          title="Our Services"
          description="We offer a comprehensive suite of services aimed at solving complex enterprise problems. These include but are not limited to:"
          data={data}
        />
      </div>

      <BottomUpdatesBanner
        title="Our Mission"
        description="To empower enterprises by providing cutting-edge software services and data solutions, guided by integrity, innovation, and a focus on customer success"
        primaryButton="Contact Us"
        onPrimaryButtonClick={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "aboutUs/bottomBanner/contactUs",
            label: "Contact Us",
          });
        }}
      />
    </div>
  );
};

export default memo(About);
