import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import Body from "./components/Body";
import { Provider } from "./widget/Context";

const App = () => {
  return (
    <BrowserRouter>
      <Provider>
        <Body>
          <Router />
        </Body>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
