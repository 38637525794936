import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./page/Home";
import constants from "./constants";
import About from "./page/About";
import Services from "./page/Services";
import ContactUs from "./page/ContactUs";
import NotFound from "./page/NotFound";
import DataphionHermes from "./page/DataphionHermes";
import ScalerMaxDatabaseSolutionLearnMore from "./page/ScalerMaxDatabaseSolutionLearnMore";
import ScrollToTopOnRouteChange from "./components/ScrollToTopOnRouteChange";
import ReactGA from "react-ga4";
import PrivacyPolicy from "./page/PrivacyPolicy";
import Blog from "./page/Blog";
import BlogDetails from "./page/BlogDetails";

const Router = () => {
  const location = useLocation();
  const capitalizeUrl = (name) => {
    const words = name.replace(/[-/]/g, " ").split(" ").filter(Boolean);

    const formattedName = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return formattedName;
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: capitalizeUrl(location.pathname),
    });
  }, [location.pathname]);

  return (
    <>
      <ScrollToTopOnRouteChange />
      <Routes>
        <Route exact path={constants.route.default} element={<Home />} />
        <Route exact path={constants.route.home} element={<Home />} />
        <Route
          path={constants.route.dataphionHermes}
          element={<DataphionHermes />}
        />
        <Route
          path={constants.route.scalerMaxDatabaseSolution}
          element={<ScalerMaxDatabaseSolutionLearnMore />}
        />
        <Route path={constants.route.aboutUs} element={<About />} />
        <Route path={constants.route.services} element={<Services />} />
        <Route path={constants.route.ContactUs} element={<ContactUs />} />
        <Route
          path={constants.route.privacyPolicy}
          element={<PrivacyPolicy />}
        />
        <Route path={constants.route.blogs} element={<Blog />} />
        <Route
          path={constants.route.blogs + "/:id"}
          element={<BlogDetails />}
        />
        <Route
          path="*"
          element={<Navigate to={constants.route.pageNotFound} />}
        />
        <Route path={constants.route.pageNotFound} element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Router;
