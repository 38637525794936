import React, { useState } from "react";
import Typography from "../widget/Typography";
import Button from "../widget/Button";
import { motion } from "framer-motion";
import constants from "../constants";
import { useNavigate } from "react-router-dom";

const MultiCards = ({
  tag,
  title,
  description,
  className,
  data,
  isLearnMore,
}) => {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const childVariants = {
    hover: {
      scale: hoveredIndex === null ? 1 : 1.1,
    },
    initial: {
      scale: 1,
    },
  };

  return (
    <div
      className={`flex flex-col items-center justify-center lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16  ${className}`}
    >
      <motion.div
        className="xs:w-full md:w-4/5 lg:w-1/2"
        variants={constants.animation.bottomTopVariant}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        {tag && (
          <Typography
            variant="body1"
            className="font-medium text-center mb-5 text-tagColor"
          >
            {tag}
          </Typography>
        )}
        {title && (
          <Typography
            className="text-center bg-blue-gradient text-transparent bg-clip-text font-extrabold"
            variant="h2"
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            className="text-center mt-4 text-bodyColor"
            variant="body1"
          >
            {description}
          </Typography>
        )}
      </motion.div>
      <motion.div
        className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 w-full lg:mt-20 md:mt-16 xs:mt-12"
        variants={constants.animation.bottomTopVariant}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.6 }}
      >
        {data?.map((item, index) => (
          <motion.div
            className="flex flex-col items-center overflow-hidden bg-white rounded-xl drop-shadow-md hover:drop-shadow-lg"
            key={index}
            onHoverStart={() => setHoveredIndex(index)}
            onHoverEnd={() => setHoveredIndex(null)}
          >
            <div className="lg:h-60 md:h-60 xs:h-40 w-full overflow-hidden">
              <motion.img
                src={item?.image}
                className="lg:h-60 md:h-60 xs:h-40 w-full mb-8 overflow-hidden object-center object-cover"
                variants={childVariants}
                animate={hoveredIndex === index ? "hover" : "initial"}
                transition={{ duration: 0.3, delay: 0.1 }}
              />
            </div>
            <div className="py-8 px-6">
              <Typography
                className="mb-4 text-center text-headingColor"
                variant="h6"
              >
                {item?.title}
              </Typography>
              <Typography variant="body2" className="font-semibold">
                {item?.description}
              </Typography>
              {item?.link && (
                <Button
                  className="w-full mt-6"
                  endIcon={<i className="fa-solid fa-angle-right text-xs" />}
                >
                  Learn more
                </Button>
              )}
            </div>
          </motion.div>
        ))}
      </motion.div>
      {isLearnMore && (
        <div className="space-x-2 flex lg:mt-20 md:mt-16 xs:mt-12">
          <Button variant="contained">Learn more</Button>
          <Button
            endIcon={<i className="fa-solid fa-angle-right text-xs " />}
            onClick={() => navigate(constants.route.ContactUs)}
          >
            Contact Us
          </Button>
        </div>
      )}
    </div>
  );
};

export default MultiCards;
