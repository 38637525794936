import React, { useContext } from "react";
import { motion } from "framer-motion";
import Context from "../widget/Context";
import Typography from "../widget/Typography";

const Sidebar = ({ title, children }) => {
  const { showSidebar, setShowSidebar } = useContext(Context);

  const sidebarVariants = {
    hidden: {
      x: "100%",
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.2,
      },
    },
  };

  if (showSidebar)
    return (
      <motion.div
        className="absolute top-0 right-0 w-full h-full h-screen bg-black bg-opacity-10 backdrop-blur-sm z-999 overflow-y-hidden"
        initial="hidden"
        animate={showSidebar ? "visible" : "hidden"}
        exit="hidden"
      >
        <motion.div
          className="w-full h-full absolute top-0 right-0"
          role="presentation"
          onClick={() => setShowSidebar(false)}
        />

        <motion.div
          className="lg:w-96 md:w-96 xs:w-full h-full absolute top-0 right-0 rounded-sm absolute z-9999 bg-white"
          variants={sidebarVariants}
        >
          <div className="flex justify-between items-center px-5 h-14 border-b bg-F3F7FD">
            <Typography className="text-bodyColor" variant="h5">
              {title}
            </Typography>
            <i
              class="fa-solid fa-xmark text-xl cursor-pointer text-bodyColor hover:text-249edc"
              onClick={() => setShowSidebar(false)}
            />
          </div>
          <div className="p-5">{children}</div>
        </motion.div>
      </motion.div>
    );
};

export default Sidebar;
