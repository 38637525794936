import React, { useState } from "react";
import Typography from "../widget/Typography";
import Button from "../widget/Button";
import { memo } from "react";
import { motion } from "framer-motion";

const ServiceCard = ({
  title,
  subTitle,
  titleSize,
  className,
  learnMoreOnClick,
  signUpOnClick,
  isBtnNotShow,
  icon,
  isIconNotShow,
  outlinedButtonName,
  primaryButtonName,
  buttonClassName,
}) => {
  const [hover, setHover] = useState(false);
  const cardItemVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      zIndex: 1,
      transition: {
        duration: 0.6,
        type: "spring",
        damping: 8,
        stiffness: 70,
      },
    },
  };

  return (
    <motion.div
      variants={cardItemVariants}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      className={`w-full relative z-99 bg-white p-8 drop-shadow-2xl rounded-xl ${className}`}
    >
      {!isIconNotShow && (
        <div className="absolute bg-white -top-10 shadow-md rounded-md overflow-hidden h-20 w-20 flex items-center justify-center">
          <i
            className={`${icon ?? "fa-solid fa-cube"} z-10 ${
              hover ? "text-white" : "text-black"
            } text-4xl`}
          />
          <motion.div
            initial={{ height: "0px" }}
            animate={{ height: hover ? "100%" : "0px" }}
            transition={{ duration: 0.4 }}
            className="absolute bottom-0 h-full w-full bg-blue-gradient"
          />
        </div>
      )}
      {title && (
        <Typography
          className="mt-8 text-headingColor"
          variant={`${titleSize ?? "h5"}`}
        >
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="body1" className="font-semibold mt-3">
          {subTitle}
        </Typography>
      )}
      {!isBtnNotShow && (
        <div className={`flex mt-4 space-x-2 ${buttonClassName}`}>
          <Button variant="contained" onClick={learnMoreOnClick}>
            {primaryButtonName ?? "Learn More"}
          </Button>
        </div>
      )}
    </motion.div>
  );
};

export default memo(ServiceCard);
