import React, { useContext, useState } from "react";
import ReactGA from "react-ga4";
import Typography from "../widget/Typography";
import { motion } from "framer-motion";
import constants from "../constants";
import InquiryCard from "../components/InquiryCard";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Context from "../widget/Context";

const ContactUs = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { screenSize } = useContext(Context);
  const bannerTitle = () => {
    if (screenSize?.width >= 1024) {
      return "Let's Start/The Conversation Today";
    } else if (screenSize?.width >= 768) {
      return "Let's Start/The Conversation Today";
    } else {
      return "Let's Start/The Conversation/Today";
    }
  };
  const childVariants = {
    hover: {
      scale: hoveredIndex === null ? 1 : 1.1,
    },
    initial: {
      scale: 1,
    },
  };

  const iconVariants = {
    hover: {
      scale: hoveredIndex === null ? -1 : 1.1,
      background: "#249edc",
      color: "#FFFFFF",
    },
    initial: {
      scale: 1,
      color: "#249edc",
      background: "#ffffff",
    },
  };

  const cardItemVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      zIndex: 1,
      transition: {
        duration: 1.4,
        type: "spring",
        damping: 5,
        stiffness: 70,
      },
    },
  };

  const branches = [
    {
      image:
        "https://images.unsplash.com/photo-1560179707-f14e90ef3623?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80",
      origin: "Chandler, Arizona, USA",
      location: "137 E Carob Drive, Chandler, Arizona - 85286",
      link: "https://www.google.com/maps/place/137+E+Carob+Dr,+Chandler,+AZ+85286,+USA/@33.2519329,-111.842615,17z/data=!4m6!3m5!1s0x872baabdf6b52dd9:0xaa6f44a9ad822f75!8m2!3d33.2519284!4d-111.8400401!16s%2Fg%2F11c2f_6nc3?entry=ttu",
    },
    {
      image:
        "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      origin: "Bangalore, Karnataka, India",
      location:
        "Sri Shanthi Towers I, 2nd Main East Of NGEF Layout Kasturi Nagar Bangalore - 560043",
      link: "https://www.google.com/maps/place/Dataphion/@13.0034294,77.6628897,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae17cca9709be3:0x24519aeea328a87f!8m2!3d13.0034294!4d77.6628897!16s%2Fg%2F11h32j9t0_?entry=ttu",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1661277712617-1e46eac647e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",
      origin: "Surat, Gujarat, India",
      location:
        "F9, Shivan Avenue, Near Platinum Park, Causeway Link Road, Katargam, Surat - 395004",
      link: "https://www.google.com/maps/place/Shivan+Avenue/@21.2304533,72.802865,17z/data=!4m7!3m6!1s0x3be04f60b21f67c1:0x35271f0a458bd039!8m2!3d21.2304483!4d72.8054399!15sClNGOSwgU2hpdmFuIEF2ZW51ZSwgTmVhciBQbGF0aW51bSBQYXJrLCBDYXVzZXdheSBMaW5rIFJvYWQsIEthdGFyZ2FtLCBTdXJhdCAtIDM5NTAwNJIBEmFwYXJ0bWVudF9idWlsZGluZ-ABAA!16s%2Fg%2F11hdyl4kpy?entry=tts&shorturl=1",
    },
  ];

  return (
    <div className="xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <Banner
        tag="Connect"
        title={bannerTitle()}
        className="bg-F3F7FD"
        subTitle="Reach out to us for a comprehensive consultation, tailored to fit the unique needs of your enterprise. Let’s build a future together."
      />
      <InquiryCard
        className="bg-f6faff"
        tag="Inquiry"
        title="Get in Touch"
        description="Fill out the form below to get in touch with us"
        image={constants.images.get_in_touch}
      />
      <div className="grid lg:grid-cols-3 md:grid-cols-1 xs:grid-cols-1 lg:gap-10 md:gap-0 xs:gap-0 lg:px-16 lg:py-20 md:px-10 xs:px-5 xs:py-16 bg-contact-us-banner bg-auto bg-center bg-no-repeat">
        <motion.div
          className="lg:col-span-3 flex flex-col justify-center items-center"
          variants={constants.animation.bottomTopVariant}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          <Typography
            variant="body1"
            className="font-medium mb-5 text-tagColor"
          >
            Contact
          </Typography>
          <Typography
            variant="h3"
            className="bg-blue-gradient text-transparent bg-clip-text font-extrabold"
          >
            Locations
          </Typography>
          <Typography variant="body1" className="my-4 text-bodyColor">
            Find our office locations and contact information below.
          </Typography>
        </motion.div>
        <motion.div
          className="col-span-3 grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-12 w-full"
          variants={constants.animation.cardsContainerVariant}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          {branches.map((item, index) => (
            <motion.div
              key={index}
              variants={cardItemVariants}
              className="flex flex-col overflow-hidden bg-white rounded-2xl drop-shadow-md hover:drop-shadow-lg"
              onHoverStart={() => setHoveredIndex(index)}
              onHoverEnd={() => setHoveredIndex(null)}
            >
              <div className={`lg:h-60 md:h-60 xs:h-40 w-full overflow-hidden`}>
                <motion.img
                  src={item.image}
                  className={`lg:h-60 md:h-60 xs:h-40 w-full mb-8 object-cover object-center`}
                  variants={childVariants}
                  animate={hoveredIndex === index ? "hover" : "initial"}
                  transition={{ duration: 0.3, delay: 0.1 }}
                />
              </div>
              <div className="flex items-center justify-between py-8 px-6">
                <div className="w-4/5">
                  <Typography className="mb-2  text-headingColor" variant="h4">
                    {item.origin}
                  </Typography>
                  <Typography className="text-bodyColor">
                    {item.location}
                  </Typography>
                </div>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(item?.link, "_blank");
                    ReactGA.event({
                      category: "Button",
                      action: `contactUs/location/${item.origin}`,
                      label: "Contact Us",
                    });
                  }}
                >
                  <motion.i
                    variants={iconVariants}
                    animate={hoveredIndex === index ? "hover" : "initial"}
                    transition={{ duration: 0.2 }}
                    className={`fa-solid fa-location-dot h-14 cursor-pointer w-14 rounded-full text-xl  flex items-center justify-center`}
                  />
                </span>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default ContactUs;
