import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import constants from "../constants";
import Typography from "../widget/Typography";
import { useNavigate, useParams } from "react-router-dom";
import Context from "../widget/Context";
import CustomCarousel from "../widget/CustomCarousel";
import Image from "../widget/Image";
import Skeleton from "../widget/Skeleton";
import MDEditor from "@uiw/react-md-editor";

const BlogDetails = () => {
  const params = useParams();
  const { getBlogs, blogData, loading } = useContext(Context);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const data = blogData?.[0];

  useEffect(() => {
    getBlogs({ id: params?.id });
  }, [params?.id]);

  useEffect(() => {
    if(blogData?.length == 0) {
        navigate(constants.route.blogs);
    }
  }, [blogData]);

  return (
    <div className="relative xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip flex flex-col items-center">
      <Header />
      <div className="lg:w-4/5 md:w-full xs:w-full lg:px-0 lg:py-10 md:px-10 xs:px-5 xs:py-16">
        {loading ? (
          <Skeleton type="text" />
        ) : (
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            variant="subtitle1"
            className="font-medium mb-2 text-center text-tagColor"
          >
            {data?.category}
          </Typography>
        )}
        {loading ? (
          <Skeleton type="text" />
        ) : (
          <Typography
            variants={constants.animation.bottomTopVariant}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            className="mb-10 text-center text-headingColor"
            variant="h3"
          >
            {data?.title}
          </Typography>
        )}
        {loading ? (
          <Skeleton type="image" />
        ) : data?.images?.data?.length <= 1 || !data?.images?.data ? (
          <Image
            className="h-320 w-full mb-8 overflow-hidden object-center object-auto rounded-xl"
            src={
              data?.images?.data
                ? "https://tracker.dataphion.com" +
                  data?.images?.data?.[0]?.attributes?.url
                : constants.images.ImageNotFound
            }
            alt={`Dataphion ${data?.images?.data?.[0]?.attributes?.name}`}
          />
        ) : (
          <CustomCarousel
            handleSlideChange={(index) => setCurrentSlide(index)}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            infiniteLoop={true}
            autoPlay
            centerMode
            interval="5000"
            centerSlidePercentage={100}
          >
            {data?.images?.data?.map((item, index) => (
              <img
                key={index}
                alt={`Dataphion ${item?.attributes?.name}`}
                src={
                  item?.attributes?.url
                    ? "https://tracker.dataphion.com" + item?.attributes?.url
                    : constants.images.ImageNotFound
                }
                className="h-320 w-full mb-8 overflow-hidden object-center object-cover rounded-xl"
              />
            ))}
          </CustomCarousel>
        )}
        {loading ? (
          <Skeleton type="text" row={10} />
        ) : (
          <div data-color-mode="light">
            <MDEditor.Markdown source={data?.description} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogDetails;
