import React, { memo, useContext } from "react";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import constants from "../constants";
import Context from "../widget/Context";

const Body = ({ children }) => {
  const location = useLocation();
  const { showSidebar } = useContext(Context);

  return (
    <div
      className={`h-screen w-full relative ${showSidebar && "overflow-hidden"}`}
    >
      <div>{children}</div>
      {location.pathname === constants.route.pageNotFound ? null : <Footer />}
    </div>
  );
};

export default memo(Body);
