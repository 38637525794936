import React from "react";
import ReactGA from "react-ga4";
import Card from "../components/Card";
import Typography from "../widget/Typography";
import constants from "../constants";
import Banner from "../components/Banner";
import Header from "../components/Header";
import BottomUpdatesBanner from "../components/BottomUpdatesBanner";
import { useNavigate } from "react-router";

const Services = () => {
  const navigate = useNavigate();

  const bannerTitle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) {
      return "Empowering Enterprises/with Cutting-Edge/Software Services and Solutions";
    } else if (screenWidth >= 768) {
      return "Empowering Enterprises/with Cutting-Edge Software/Services and Solutions";
    } else {
      return "Empowering/Enterprises with/Cutting-Edge/Software/Services & Solutions";
    }
  };

  const services = {
    integration_of_llm: [
      {
        title: "API-First Architecture",
        description: "Promotes application integration and extensibility.",
      },
      {
        title: "Event-Driven Microservices",
        description: "Optimize application performance in real-time.",
      },
      {
        title: "Serverless Computing",
        description: "On-demand, cost-effective cloud resource utilization.",
      },
      {
        title: "Data Encryption",
        description:
          "Ensuring robust security protocols for sensitive information.",
      },
      {
        title: "OAuth 2.0 Authorization",
        description: "Industry-standard protocol for secure authorization.",
      },
    ],
    enterprise_software_development: [
      {
        title: "Domain-Driven Design",
        description: "Complex problem-solving focused on core business needs.",
      },
      {
        title: "Scalable Architectures",
        description: "Monolithic, microservices, or serverless options.",
      },
      {
        title: "High Availability",
        description: "Ensuring uptime through redundancy and fault-tolerance.",
      },
      {
        title: "Agile Methodology",
        description: "Iterative development for faster, flexible solutions.",
      },
      {
        title: "Multi-Tenancy Support",
        description: "Efficient resource use in SaaS applications.",
      },
      {
        title: "RESTful API Design",
        description: " Enable seamless communication between components.",
      },
      {
        title: "AI and ML Implementation",
        description:
          "Incorporating intelligent features for analytics, automation, and data processing.",
      },
    ],
    mobile_app_development: [
      {
        title: "Cross-Platform Optimization",
        description: "Code once, deploy everywhere.",
      },
      {
        title: "Reactive Programming",
        description: "Enhance app responsiveness and user experience.",
      },
      {
        title: "Native Modules",
        description: "Optimized performance for specific OS features.",
      },
      {
        title: "Geolocation Services",
        description: "Real-time location-based functionalities.",
      },
      {
        title: "Offline Syncing",
        description: "Ensuring app usability without internet connection.",
      },
      {
        title: "Advanced Animations",
        description: "Creating engaging, interactive UI.",
      },
      {
        title: "MVP Architecture",
        description: "Minimal Viable Product for rapid market testing.",
      },
      {
        title: "Push Notifications",
        description: "For real-time user engagement.",
      },
    ],
    devOps_sre: [
      {
        title: "Infrastructure as Code (IaC)",
        description: "Reproducible, scalable systems.",
      },
      {
        title: "Continuous Monitoring and Logging",
        description: "Real-time system health insights.",
      },
      {
        title: "Automated Rollbacks",
        description: "Ensuring system stability during failed deployments.",
      },
      {
        title: "Zero Downtime Deployments",
        description: "For uninterrupted service.",
      },
      {
        title: "Traffic Shaping",
        description: "Managing resource allocation based on user load.",
      },
      {
        title: "Container Orchestration",
        description: "Utilizing Kubernetes for better resource management.",
      },
      {
        title: "SLA and SLO Management",
        description: "For service quality maintenance.",
      },
      {
        title: "Incident Response Protocols",
        description: "Quick resolution of operational issues.",
      },
    ],
    consulting: [
      {
        title: "ETL Pipelining",
        description: "Efficient Extract, Transform, Load (ETL) processes.",
      },
      {
        title: "Data Sharding and Partitioning",
        description: "Strategic data organization.",
      },
      {
        title: "Data Governance",
        description: "Ensuring data quality and security.",
      },
      {
        title: "Real-Time Analytics",
        description: "For immediate insights into your data.",
      },
      {
        title: "TMachine Learning Algorithms",
        description: "Predictive analytics and automation.",
      },
      {
        title: "Optimized Data Queries",
        description: "For faster data retrieval.",
      },
      {
        title: "Data Lake Management",
        description: "Holistic solutions for data storage and retrieval.",
      },
      {
        title: "Data Warehouse Cubing",
        description: "Multi-dimensional data analysis and visualization.",
      },
    ],
  };

  return (
    <div className="xs:overflow-x-hidden lg:overflow-x-clip xs:overflow-x-clip">
      <Header />
      <Banner
        className="bg-F3F7FD"
        tag="Innovation"
        title={bannerTitle()}
        subTitle="Discover our comprehensive range of services, including Full Stack, Flutter, React Native, DevOps and Cloud Solutions.."
        primaryButton="Contact Us"
        onClickPrimary={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "services/banner/contactUs",
            label: "Contact Us",
          });
        }}
      />
      <div className="bg-service-banner bg-cover bg-center flex lg:justify-end xs:justify-center">
        <div className="w-280 h-full bg-white drop-shadow-md lg:mx-32 xs:mx-6 p-10">
          <div className="grid grid-cols-1 gap-6">
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="h3"
              className="bg-blue-gradient text-transparent bg-clip-text font-extrabold"
            >
              Introduction
            </Typography>
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="subtitle2"
              className="text-headingColor font-semibold"
            >
              Transform your business through technological innovation with our
              suite of specialized services. We offer a broad range of
              capabilities designed to solve complex challenges and accelerate
              digital transformation.
            </Typography>
            <Typography
              variants={constants.animation.bottomTopVariant}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
              variant="subtitle2"
              className="text-headingColor font-semibold"
            >
              We are committed to providing unparalleled services that cater to
              your every business need. In today’s fast-paced digital landscape,
              we understand that efficiency, scalability, and reliability are
              more than just buzzwords; they are the pillars that uphold
              successful enterprises. That's why our suite of services is
              designed to offer you the very best in software development, data
              management, and operational optimization.
            </Typography>
          </div>
        </div>
      </div>
      <Card
        className="bg-card-background bg-cover bg-right bg-no-repeat"
        title="Integration of LLM into Enterprise Products"
        description="Streamline your digital workflow by seamlessly incorporating LLM (Low-Code, No-Code) capabilities into your existing enterprise applications."
        image={constants.images.integration_of_llm}
      >
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-6 mt-4">
          {services.integration_of_llm.map((item, index) => (
            <div key={index}>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="subtitle2"
                className="text-headingColor font-semibold"
              >
                {item.title}
              </Typography>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="body1"
                className="font-semibold text-sm"
              >
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
      <Card
        className="bg-f6faff"
        title="Enterprise Software Development"
        description="Custom software solutions engineered to meet the unique demands of your enterprise."
        image={constants.images.enterprise_software_development_service}
        reverse
      >
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-6 mt-4">
          {services.enterprise_software_development.map((item, index) => (
            <div key={index}>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="subtitle2"
                className="text-headingColor font-semibold"
              >
                {item.title}
              </Typography>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="body1"
                className="font-semibold text-sm"
              >
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
      <Card
        className="bg-card-background2 bg-cover bg-left bg-no-repeat"
        title="Mobile App Development Using Flutter & React Native"
        description="Deliver unparalleled user experiences across platforms with our cutting-edge mobile app development services."
        image={constants.images.mobile_app_development_service}
      >
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-6 mt-4">
          {services.mobile_app_development.map((item, index) => (
            <div key={index}>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="subtitle2"
                className="text-headingColor font-semibold"
              >
                {item.title}
              </Typography>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="body1"
                className="font-semibold text-sm"
              >
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
      <Card
        className="bg-f6faff"
        title="DevOps & SRE (Site Reliability Engineering)"
        description="Boost operational efficiency and product reliability with our DevOps and SRE services."
        image={constants.images.streamline_devops}
        reverse
      >
        <div className="grid lg:grid-cols-2 xs:grid-cols-1   gap-6 mt-4">
          {services.devOps_sre.map((item, index) => (
            <div key={index}>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="subtitle2"
                className="text-headingColor font-semibold"
              >
                {item.title}
              </Typography>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="body1"
                className="font-semibold text-sm"
              >
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
      <Card
        className="bg-card-background bg-cover bg-right bg-no-repeat"
        title="Apache Spark, Data Lake & Warehouse Consulting"
        description="Navigate the complexities of big data and analytics with our expert consulting services."
        image={constants.images.warehouse_consulting}
      >
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-6 mt-4">
          {services.consulting.map((item, index) => (
            <div key={index}>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="subtitle2"
                className="text-headingColor font-semibold"
              >
                {item.title}
              </Typography>
              <Typography
                variants={constants.animation.bottomTopVariant}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variant="body1"
                className="font-semibold text-sm"
              >
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
      <BottomUpdatesBanner
        title="Discover the Future with Our Expertise"
        description="Let's build the future, together. Contact us to explore how our specialized services can benefit your business."
        primaryButton="Contact Us"
        secondaryButton="Request"
        onPrimaryButtonClick={() => {
          navigate(constants.route.ContactUs);
          ReactGA.event({
            category: "Button",
            action: "settings/bottomBanner/contactUs",
            label: "Contact Us",
          });
        }}
      />
    </div>
  );
};

export default Services;
